@use "../styles/utils";
@use "../styles/breakpoints.module.scss";

.outerContainer {
  overflow: hidden;
  display: flex;
  flex-direction: column;

  min-width: breakpoints.$xxs;

  /*
   * Padding added to account for the safe area insets on mobile devices.
   * Height must have this padding subtracted otherwise will result in an
   * unnecessary scrollbar.
   */
  height: calc(100dvh - env(safe-area-inset-top) - env(safe-area-inset-bottom));
  margin: 0 auto;

  background-color: var(--surface-neutral-secondary-default-fill);

  // Prevent focus ring appearing after closing modals (Safari)
  &:focus {
    outline: none;
  }
}

.innerContainer {
  position: relative;
  display: flex;

  &.none {
    height: 100%;
  }

  &.regular {
    height: calc(100% - var(--window-controls-bar-height-regular));
  }

  &.large {
    height: calc(100% - var(--window-controls-bar-height-large));
  }
}

.workspace {
  // Should take precedence over the library drawer
  z-index: 1;

  overflow: hidden;
  display: grid;
  grid-template-rows: [header] auto [content] 1fr;

  width: 100%;

  box-shadow: var(--elevation-3);

  &.rounded {
    border-radius: 16px 16px 0 0;
  }
}

.contentArea {
  overflow: hidden;
  display: flex;
  flex-direction: row-reverse;
  height: 100%;
}

.editorWorkspace {
  overflow: hidden;
  display: flex;
  flex-direction: column;
  flex-shrink: 100;

  width: 100%;

  background-color: var(--surface-neutral-primary-default-fill);

  .withDrawer & {
    @media (max-width: #{breakpoints.$sm}) {
      width: 0;
      padding: 0;
      visibility: hidden;
      opacity: 0;
    }
  }
}

.formattingBar {
  position: relative;
  z-index: 1;
  padding: var(--spacing-12px) var(--spacing-16px) var(--spacing-0px)
    var(--spacing-16px);

  &::after {
    pointer-events: none;
    content: "";

    position: absolute;
    right: var(--spacing-16px);
    left: var(--spacing-16px);
    transform: translateY(-10px);

    display: block;

    height: 30px;

    background-image: linear-gradient(
      to bottom,
      var(--surface-neutral-primary-default-fill) 0%,
      transparent 100%
    );
  }
}

.editor {
  position: relative;

  overflow-y: auto;
  flex: 1;

  padding: var(--spacing);
  padding-top: 0;

  @media (width > #{breakpoints.$sm}) {
    --spacing: var(--spacing-24px);
  }

  @media (width <= #{breakpoints.$sm}) {
    --spacing: var(--spacing-16px);
  }
}

.notifications {
  position: relative;
  display: flex;
  justify-content: center;
}

.dictationControls {
  position: relative;
  display: flex;
  justify-content: center;

  &::before {
    pointer-events: none;
    content: "";

    position: absolute;
    right: var(--spacing-16px);
    bottom: 100%;
    left: var(--spacing-16px);
    transform: translateY(10px);

    display: block;

    height: 30px;

    background-image: linear-gradient(
      to top,
      var(--surface-neutral-primary-default-fill) 0%,
      transparent 100%
    );
  }
}
