@font-face {
  font-family: OpenDyslexic;
  font-weight: 400;
  font-style: normal;
  src: url("./fonts/OpenDyslexic-Regular.otf");
}

@font-face {
  font-family: OpenDyslexic;
  font-weight: 400;
  font-style: italic;
  src: url("./fonts/OpenDyslexic-Italic.otf");
}

@font-face {
  font-family: OpenDyslexic;
  font-weight: 700;
  font-style: normal;
  src: url("./fonts/OpenDyslexic-Bold.otf");
}

@font-face {
  font-family: OpenDyslexic;
  font-weight: 700;
  font-style: italic;
  src: url("./fonts/OpenDyslexic-BoldItalic.otf");
}

@font-face {
  font-family: "Plus Jakarta Sans";
  font-weight: 100 900;
  font-style: normal;
  src: url("./fonts/PlusJakartaSans-VariableFont_wght.ttf");
}

@font-face {
  font-family: "Plus Jakarta Sans";
  font-weight: 100 900;
  font-style: italic;
  src: url("./fonts/PlusJakartaSans-Italic-VariableFont_wght.ttf");
}

@font-face {
  font-family: "Open Sans";
  font-weight: 100 900;
  font-style: normal;
  src: url("./fonts/OpenSans-VariableFont_wdth,wght.ttf");
}

@font-face {
  font-family: "Open Sans";
  font-weight: 100 900;
  font-style: italic;
  src: url("./fonts/OpenSans-Italic-VariableFont_wdth,wght.ttf");
}

@font-face {
  font-family: Lexend;
  font-weight: 100 900;
  font-style: normal;
  src: url("./fonts/Lexend-VariableFont_wght.ttf");
}
