.xs {
  --icon-size: 16px;
  --padding-x: var(--spacing-12px);
  --height: 36px;
  --font: var(--text-xs-medium);
}

.sm {
  --icon-size: 20px;
  --padding-x: var(--spacing-12px);
  --height: 40px;
  --font: var(--text-sm-medium);
}

.md {
  --icon-size: 24px;
  --padding-x: var(--spacing-16px);
  --height: 48px;
  --font: var(--text-md-medium);
}

.lg {
  --icon-size: 24px;
  --padding-x: var(--spacing-16px);
  --height: 56px;
  --font: var(--text-lg-medium);
}

.wrapper {
  position: relative;
  display: block;
}

.icon {
  position: absolute;
  top: 50%;
  left: var(--padding-x);
  transform: translateY(-50%);

  width: var(--icon-size);
  height: var(--icon-size);
}

.button {
  position: absolute;
  top: 50%;
  right: var(--padding-x);
  transform: translateY(-50%);

  display: block;
}

.input {
  width: 100%;
  height: var(--height);
  padding: var(--spacing-8px) var(--padding-x);

  font: var(--font);

  background: var(--surface-neutral-primary-default-fill);
  border: var(--stroke-weight-1px) solid
    var(--surface-neutral-primary-default-stroke);
  border-radius: var(--radius-8px);

  transition: background-color 250ms;

  &::placeholder {
    color: var(--text-on-neutral-primary-tertiary-default);
  }

  // Hide the clear button in Chrome
  &::-webkit-search-decoration,
  &::-webkit-search-cancel-button,
  &::-webkit-search-results-button,
  &::-webkit-search-results-decoration {
    display: none;
  }

  // Hide the clear button in Edge
  &::-ms-reveal {
    display: none;
    width: 0;
    height: 0;
  }

  .icon + & {
    padding-left: calc(
      var(--spacing-12px) + var(--icon-size) + var(--spacing-8px)
    );
  }

  &:hover {
    background-color: var(--surface-neutral-primary-hover);
  }

  &:focus {
    border-color: var(--surface-neutral-primary-focus-stroke);
  }

  &[aria-invalid="true"] {
    border-color: var(--surface-destructive-primary-default-fill);
    border-width: 2px;
    outline-offset: 2px;
  }

  .wrapper:has(.button) & {
    padding-right: calc(
      var(--padding-x) + var(--button-wrapper-width) + var(--spacing-8px)
    );
  }
}
