@use "../styles/utils";
@use "../styles/base/form";

.main {
  overflow: auto;
  display: flex;
  flex-direction: column;
  align-items: center;

  height: 100dvh;
  min-height: 100dvh;
  max-height: 100dvh;

  color: var(--text-on-brand-primary-primary-default);

  background: linear-gradient(
    180deg,
    /* TODO: Replace these with themed variables */
      var(--colour-primary-blue-blue-600) 0%,
    /* TODO: Replace these with themed variables */
      var(--colour-primary-blue-blue-800) 100%
  );
}

@keyframes slide {
  0% {
    transform: translateY(3rem);
  }

  100% {
    transform: translateY(0);
  }
}

.container {
  position: relative;

  display: flex;
  flex-direction: column;
  align-items: center;
  justify-content: center;

  max-width: utils.rem(482);
  margin: auto;
  padding: var(--spacing-16px);
  padding-bottom: 10vh;

  text-align: center;

  animation: fade 0.3s both, slide 1s both;
}

.title {
  margin-bottom: var(--spacing-16px);
  font: var(--display-sm-bold);
  line-height: 1.4;

  &:last-child {
    margin-bottom: 28px;
  }
}

.subtitle {
  margin-bottom: var(--spacing-20px);
  font: var(--text-md-medium);

  &:empty {
    display: none;
  }
}

.ctas {
  display: flex;
  flex-direction: column;
  gap: var(--spacing-12px);
  align-items: center;

  width: 100%;
  max-width: 100%;
  padding: 0;
}

.cta {
  --button-width: 240px;

  margin: auto;
}

.explainer {
  max-width: 330px;
  font: var(--text-xs-medium);
  color: var(--colour-primary-blue-blue-100);
  text-align: center;
}

.explainertop {
  padding-top: var(--spacing-4px);
}

.logo {
  width: 127px;
  margin-bottom: var(--spacing-16px);
}

.waves {
  position: fixed;
  bottom: 0;
  transform: scale(1.3);

  width: 100vw;
  height: min(325px, 30vh);
}

@keyframes spin {
  0% {
    transform: rotate(0deg);
  }

  100% {
    transform: rotate(360deg);
  }
}

@keyframes fade {
  0% {
    opacity: 0;
  }

  100% {
    opacity: 1;
  }
}

.loadingContainer {
  display: flex;
  flex-direction: column;
  align-items: center;
  justify-content: center;

  height: 100%;
}

.loading {
  width: 4rem;
  height: 4rem;
  animation: spin 1s steps(8, end) infinite, fade 2s both 1s;

  mask-image: conic-gradient(from 10deg, transparent, #000);

  @media (prefers-reduced-motion: reduce) {
    mask-image: none;
  }
}

.prompt {
  position: fixed;
  top: 0;

  /* This one might appear as unnecessary at first glance but is
    when the screen gets quite narrow for some reason, otherwise
    a gap appears! */
  left: 0;
  width: 100%;
}
