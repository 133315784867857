.container {
  display: grid;
  grid-template-rows: [header] var(--spacing-12px) [main] 1fr;
  gap: var(--spacing-6px);

  padding: var(--spacing-8px);

  -webkit-app-region: drag;

  & button {
    -webkit-app-region: no-drag;
  }
}

.header {
  display: flex;
  justify-content: space-between;
  width: 100%;
}

.main {
  display: flex;
  flex-direction: column;
  gap: var(--spacing-6px);
  align-items: center;
  justify-content: center;
}

.win {
  flex-direction: row;
}

.mac {
  flex-direction: row-reverse;
}
