@use "./utils";

/* ---------------------------------- *\
|         ⚠️ Custom Variables ⚠️         |
|  Only for use as a temporary escape  |
|    hatch until designs are updated   |
\* ---------------------------------- */

:root {
  --spacing-2px: 2px;
  --spacing-5px: 5px;
  --spacing-6px: 6px;
  --spacing-10px: 10px;
  --spacing-20px: 20px;
  --radius-3px: 3px;
  --drawer-transition-duration: 250ms;
  --window-controls-bar-height-regular: 32px;
  --window-controls-bar-height-large: 52px;
}
