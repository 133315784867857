/**
 * Theme mixin
 *
 * Takes a colour scheme, e.g. "dark"|"light" and creates a media query with
 * prefers-color-scheme and a descendant selector with the scheme as a class
 * name.
 *
 * @example
 * // input:
 * @include theme(dark) { ... style rules ... }
 *
 * // output:
 * @media (prefers-color-scheme: dark) { ... style rules ... }
 * body.dark { ... style rules ... }
 */
 @mixin theme($schemes...) {
  @each $scheme in $schemes {
    .#{$scheme},
    &.#{$scheme} {
      @content;
    }

    @media (prefers-color-scheme: $scheme) {
      @content;
    }
  }
}

@function rem($pixelValue) {
  @return $pixelValue * 0.0625rem;
}

@mixin visuallyHidden {
  user-select: none;

  position: absolute;

  overflow: hidden !important;

  width: 1px;
  height: 1px;

  clip-path: polygon(0 0, 0 0, 0 0);
}

@mixin hoverActive {
  @media (hover: hover) and (pointer: fine) {
    &:hover,
    &:active {
      @content;
    }
  }
}
