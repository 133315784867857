@use "../styles/utils";

.container {
  display: flex;
  flex-direction: column;
  gap: var(--spacing-16px);
  align-items: center;
  justify-content: center;

  width: 100%;
  min-height: 100%;
  padding: var(--spacing-24px);

  /*
   * Apply responsive offset to vertical alignment
   *
   * The items are designed to be centered, but with a certain offset.
   * However, this causes issues at small viewports leading to content
   * clipping and overflowing (as well as an unnecessary scrollbar).
   *
   * Therefore, as the screen becomes smaller the amount of offset is reduced.
   *
   * Formula: subtract 500px from 100% height and use 30% of whatever is left
   */
  padding-bottom: calc((100dvh - 500px) * 0.3);
}

.text {
  display: flex;
  flex-direction: column;
  gap: var(--spacing-8px);
  align-items: center;

  text-align: center;
}

.title {
  max-width: utils.rem(192);
  font: var(--text-sm-bold);
  color: var(--text-on-neutral-primary-secondary-default);
}

.body {
  max-width: utils.rem(272);
  font: var(--text-sm-regular);
  color: var(--text-on-neutral-primary-secondary-default);
}
