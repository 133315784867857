@use "../../../styles/utils.scss";

.optionContainer {
  @include utils.hoverActive {
    &:not(.selected) {
      & .pill {
        color: var(--text-on-neutral-primary-secondary-hover);
        background: var(--surface-neutral-primary-hover);
        border: 1px solid var(--surface-neutral-primary-default-stroke);
      }

      & .previewImg {
        outline-color: var(--surface-brand-secondary-default-stroke);
      }
    }
  }

  cursor: pointer;

  display: flex;
  flex-direction: column;
  gap: 8px;
  align-items: center;
}

.pill {
  padding: var(--spacing-2px) 6px;

  font: var(--text-sm-semibold);
  color: var(--text-on-neutral-primary-secondary-default);

  border: 1px solid transparent;
  border-radius: var(--radius-8px);

  .selected & {
    color: var(--text-on-brand-primary-primary-default);
    background: var(--surface-brand-primary-default);
  }
}

.previewImg {
  padding: 2px;
  border-radius: 8px;
  outline: 2px solid transparent;

  .selected & {
    outline-color: var(--surface-brand-primary-default-stroke);
  }

  .radioInput:focus-visible + & {
    outline: var(--outline);
  }

  svg {
    display: block;
    width: 100%;
  }
}

.radioInput {
  @include utils.visuallyHidden;
}
