@use "../../styles/base/list-item";
@use "../../styles/base/list";
@use "../../styles/base/surface";

$container-padding-top: var(--spacing-16px);
$listing-height: 40px;

.container {
  overflow: auto;
  display: flex;
  flex-direction: column;
  gap: var(--spacing-8px);
  align-self: stretch;

  max-height: 100%;
  padding: $container-padding-top var(--spacing-12px);

  &,
  ul {
    list-style: none;
  }
}

.explainer {
  position: sticky;
  z-index: 2;

  // For some reason, there can be a 1px bleed-over from the sticky element so
  // we need to offset it by an extra 1px.
  top: calc(-1 * $container-padding-top - 1px);

  margin: calc(-1 * var(--spacing-8px)) calc(-1 * var(--spacing-8px))
    $listing-height calc(-1 * var(--spacing-8px));
  padding: var(--spacing-12px) var(--spacing-16px) var(--spacing-6px)
    var(--spacing-16px);

  font: var(--text-xs-medium);
  color: var(--text-on-neutral-primary-secondary-default);

  background-color: var(--surface-neutral-secondary-default-fill);

  + * {
    margin-top: -$listing-height;
  }
}

.section {
  display: flex;
  flex-direction: column;
  gap: var(--spacing-10px);
  justify-content: center;

  padding: var(--spacing-10px) var(--spacing-8px) var(--spacing-8px);

  background: var(--surface-neutral-primary-default-fill);
  border-radius: var(--radius-12px);
  box-shadow: var(--elevation-1);
}

.title {
  overflow: hidden;
  display: flex;
  gap: var(--spacing-8px);
  align-items: center;

  padding: 0 var(--spacing-8px);

  font: var(--text-xs-bold);
  color: var(--text-on-neutral-primary-primary-default);
  text-overflow: ellipsis;
}

.icon {
  width: 24px;
  height: 24px;
  color: var(--icon-on-brand-secondary-primary-default);
}

.subsectionList {
  display: flex;
  flex-direction: column;
  gap: var(--spacing-8px);
}

.subsectionItem {
  display: flex;
  flex-direction: column;
  gap: 1px;

  background: var(--surface-neutral-secondary-default-fill);
  border-radius: var(--radius-8px);
}

.commandList {
  padding: var(--spacing-8px);
  border-top: var(--stroke-weight-1px, 1px) solid
    var(--surface-neutral-primary-default-stroke);
}

.button {
  overflow: hidden;
  display: flex;
  justify-content: space-between;

  width: 100%;
  padding: var(--spacing-8px) var(--spacing-12px) var(--spacing-8px)
    var(--spacing-16px);

  font: var(--text-xs-bold);
  color: var(--text-on-neutral-primary-secondary-default);
  text-overflow: ellipsis;

  border: none;
}

.list {
  @extend %surface;
  @extend %list;

  /**
   * In items with subsections, if we don't include this
   * the rounded corners will get cut off
   */
  &:not(:last-child){
    margin-bottom: var(--spacing-12px);
  }
}

.listItem {
  @extend %list-item;
}
