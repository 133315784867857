@use "../styles/base/popover";
@use "../styles/base/surface";

.popover {
  @extend %popover;
}

.container {
  pointer-events: none;
  position: relative;
  display: flex;
  flex-direction: column;

  & > * {
    pointer-events: auto;
  }
}

.contents {
  @extend %surface;

  display: flex;
  flex-direction: column;
  gap: 2px;

  width: max-content;
  max-width: 280px;
  margin-bottom: 2px;
  padding: 4px;

  list-style: none;

  border: 1px solid
    var(--surface-neutral-primary-default-stroke);
}
