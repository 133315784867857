@use "../../styles/base/popover";
@use "../../styles/base/surface";

.container {
  position: relative;
}

.popover {
  @extend %popover;
  @extend %surface;

  display: flex;
  flex-direction: column;
  gap: var(--spacing-2px);

  margin-top: 4px;
  padding: var(--spacing-4px);

  border: var(--stroke-weight-1px) solid
  var(--surface-neutral-primary-default-stroke);
}

.spacer {
  align-self: center;

  width: calc(100% - var(--spacing-8px));
  height: var(--stroke-weight-1px);

  background: var(--surface-neutral-primary-default-stroke);
  border: none;
}
