@use "../../styles/base/list-item";
@use "../../styles/utils";

%element {
  overflow: hidden;

  width: 100%;

  text-align: left;
  text-overflow: ellipsis;
  white-space: nowrap;
}

/*
 * These rules describe the styling of a library row list item.
 *
 * 1. A list item's Menu button is shown:
 * - Always for touch devices
 *   - This has significant implications for iOS devices as otherwise,
 *     Safari forces a hover event on tap, meaning switching document goes
 *     from being a single tap action to two.
 * - When the list item is focus-visible
 * - When its related popover is already open (to avoid disorienting the user)
 * - On hover
 *
 * 2. A list item's hover style is not applied when hovering its related
 *    popover, unless it is checked. This is to retain interactivity when
 *    switching hover target between the list item and the popover.
 *
 * 3. A highlight style is applied when the list item contained a checked
 *    input.
 */
%list-item-with-popover-menu {
  & .menu {
    /*
     * Visually hidden but still visible by screen readers - this is on purpose.
     * We want screen reader users to be aware there is a menu button per item
     * without having to navigate to each one and be surprised when buttons
     * appear!
     */
    opacity: 0;
  }

  /* stylelint-disable order/order */

  /**
   * This NEEDS to be after the & .menu above
   * otherwise it will be overriden
   */
  @include utils.isTouch {
    & .menu {
      opacity: 1;
    }
  }

  /* stylelint-enable */

  &:has(:focus-visible) {
    & .menu {
      opacity: 1;
    }
  }

  &:has([popover]:popover-open),
  &:hover:not(:has([popover]:hover)) {
    & .menu {
      opacity: 1;
    }
  }

  &:hover:not(:has([popover]:hover), :has(input:checked)) {
    background-color: var(--surface-neutral-primary-hover);
    border-color: var(--surface-neutral-primary-default-stroke);
  }

  &:has(input:checked) {
    background-color: var(--surface-brand-secondary-default);
    border-color: var(--surface-brand-secondary-default-stroke);
  }
}

.label {
  cursor: pointer;

  overflow: hidden;
  display: flex;
  flex-direction: column;
  flex-grow: 1;

  padding: 8px;
}

.listItem {
  @extend %list-item-with-popover-menu;
  @extend %list-item;

  align-items: center;
}

.title {
  @extend %element;

  font: var(--text-xs-semibold);
  color: var(--text-on-neutral-primary-primary-default);
}

.preview {
  @extend %element;

  font: var(--text-xs-medium);
  color: var(--text-on-neutral-primary-secondary-default);
}

.input {
  appearance: none;

  &:focus-visible {
    outline: none;
  }
}
