@use "../styles/base/list-item";
@use "../styles/base/list";
@use "../styles/base/surface";
@use "../styles/utils";

.container {
  display: flex;
  flex-direction: column;
  justify-content: space-between;
  height: 100%;
}

.explainer {
  position: sticky;
  z-index: 2;
  top: 0;

  margin: 0 calc(-1 * var(--spacing-12px));
  padding: var(--spacing-12px) var(--spacing-24px) var(--spacing-6px)
    var(--spacing-24px);

  font: var(--text-xs-bold);
  color: var(--text-on-neutral-primary-secondary-default);

  background-color: var(--surface-neutral-secondary-default-fill);
}

.content {
  overflow-y: auto;
  display: flex;
  flex-direction: column;
  gap: var(--spacing-2px);

  padding: var(--spacing-0px) var(--spacing-12px) var(--spacing-12px)
    var(--spacing-12px);
}

.footer {
  display: flex;
  align-items: center;
  justify-content: center;

  padding: var(--spacing-16px) var(--spacing-12px);

  background-color: var(--surface-neutral-secondary-default-fill);
  border-top: var(--stroke-weight-1px) solid
    var(--surface-neutral-secondary-default-stroke);
}

.list {
  @extend %list;
  @extend %surface;
}

.listItem {
  @extend %list-item-with-hover;
  @extend %list-item;
}
