@use "../styles/base/list";
@use "../styles/base/list-item";
@use "../styles/base/surface";
@use "../styles/utils";

.content {
  overflow-y: auto;
  width: 100%;
  padding: var(--spacing-16px) var(--spacing-12px);
}

.footer {
  display: flex;
  align-items: center;
  justify-content: center;

  padding: var(--spacing-16px) var(--spacing-12px);

  background-color: var(--surface-neutral-secondary-default-fill);
  border-top: var(--stroke-weight-1px) solid
  var(--surface-neutral-secondary-default-stroke);
}

.list {
  @extend %list;
  @extend %surface;
}

.listItem {
  @extend %list-item-with-hover;
  @extend %list-item;
}
