$animation-timing: 0.2s;

@keyframes open {
  0% {
    overflow: hidden;
  }

  // Ensure that overflow is hidden all the way until the animation is complete
  // without this, the overflow changes half way through the animation causing
  // content to spill out
  99% {
    overflow: hidden;
  }

  100% {
    overflow: visible;
  }
}

%button {
  position: relative;
  z-index: 1;
  border-radius: var(--radius-8px);
  transition: border-radius 0.2s;
}

.buttonCollapsed {
  @extend %button;
}

.buttonExpanded {
  @extend %button;

  border-radius: var(--radius-8px) var(--radius-8px) 0 0;
}

.collapsedContent {
  overflow: hidden;
  max-height: 0;
  visibility: hidden;
  transition:
    max-height $animation-timing cubic-bezier(0, 0.95, 0, 0.95),
    visibility $animation-timing 0s;
}

.expandedContent {
  // Set a sensible "large" amount of max-height to allow for content to grow.
  // CSS cannot animate to "auto" max-height, so we need to set something that
  // is larger than the content will likely ever be.
  max-height: 500vh;
  visibility: visible;
  transition:
    max-height $animation-timing cubic-bezier(0.95, 0, 0.95, 0),
    visibility $animation-timing 0s;
  animation: open $animation-timing forwards;
}

%arrow {
  display: flex;
  gap: var(--spacing-4px);
  align-items: center;
  justify-content: center;

  width: 16px;
  height: 16px;

  transition: $animation-timing;
}

.arrow {
  @extend %arrow;
}

.arrowInverted {
  @extend %arrow;

  transform: rotate(-180deg);
}
