.header {
  position: sticky;
  z-index: 1;

  display: flex;
  align-items: center;
  justify-content: flex-end;

  min-height: 52px;
  padding: 0 16px;
}
