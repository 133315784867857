.listingButton {
  display: flex;
  align-items: flex-start;

  width: 100%;

  border: none;
  border-radius: inherit;

  &.destructive {
    &:hover, &:focus-visible {
      color: var(--icon-on-destructive-secondary-primary-default);
    }
  }
}
