@use "sass:list";
@use "./widths.scss";

.container {
  user-select: none;

  position: relative;
  z-index: 1;

  container: formatting / inline-size;
  display: flex;
  gap: var(--spacing-4px);
  align-items: center;
  justify-content: center;

  max-width: calc(100dvw - var(--spacing-32px));
  height: 100%;
  padding: var(--spacing-4px);

  background: var(--surface-neutral-secondary-default-fill);
  border-radius: var(--radius-8px);
}

.formattingBarButton {
  user-select: none;
  height: 100%;

  @for $i from 1 through list.length(widths.$widths) {
    @container formatting (max-width: #{list.nth(widths.$widths, $i)}) {
      &:nth-of-type(#{$i}) {
        display: none;
      }
    }
  }
}

.spacer {
  width: 1px;
  height: 90%;
  margin: 0 var(--spacing-4px);
  background: var(--surface-neutral-secondary-default-stroke);
}

.submenu {
  @container formatting(min-width: #{list.nth(widths.$widths, 1)}) {
    display: none;
  }
}
