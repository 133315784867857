.icon {
  min-width: 20px;
  max-width: 20px;
  min-height: 20px;
  max-height: 20px;
}

.button {
  cursor: pointer;

  display: flex;
  flex: 1 0 0;
  gap: var(--spacing-8px);
  align-items: center;

  font: var(--text-sm-medium);
  white-space: nowrap;

  border: none;

  &:disabled,
  &:has(:disabled) {
    cursor: default;
  }

  &:focus-visible {
    outline: none;
  }
}

.label {
  display: block;
  margin-right: 20px;
}

.checkbox {
  margin-left: auto;
}
