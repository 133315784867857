.container {
  display: flex;
  gap: 3px;
  align-items: center;
  justify-content: center;

  height: 100%;
}

.bar {
  flex-shrink: 0;

  width: 2px;
  min-height: 4px;

  background-color: white;
  border-radius: 8px;

  transition: height 100ms ease-out;
}
