.label {
  cursor: pointer;

  display: flex;
  flex-shrink: 0;
  align-items: center;
  justify-content: space-between;

  min-height: 40px;
  padding: var(--spacing-8px) 6px var(--spacing-8px) var(--spacing-12px);

  font: var(--text-sm-regular);

  background: var(--surface-neutral-primary-default-fill);
  border: var(--stroke-weight-1px) solid transparent;
  border-radius: var(--radius-8px);

  transition: background 250ms, color 250ms, border-color 250ms;

  &:disabled {
    background: var(--surface-neutral-primary-disabled);
  }

  &:has(input:focus-visible) {
    outline: var(--outline);
  }

  &:hover:not(:disabled, .selected) {
    background: var(--surface-neutral-primary-hover);
    border-color: var(--surface-neutral-primary-default-stroke);
  }
}

.selected {
  background: var(--surface-brand-secondary-default);
  border-color: var(--surface-brand-secondary-default-stroke);
}

.bold {
  font-weight: bold;
}

.italic {
  font-style: italic;
}
