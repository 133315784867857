// Quite a few magic numbers here, but all just trying to match the native OS.

.closeButton {
  --close-red: #ed6a5e;
  --close-red-active: #dd9889;
  --close-red-icon: #900;
  --close-red-icon-active: #190000;

  cursor: default;

  position: relative;

  box-sizing: border-box;
  width: 12px;
  height: 12px;

  background-color: var(--close-red);
  border: none;
  border-radius: 50%;

  &:hover {
    &::before,
    &::after {
      content: "";

      position: absolute;
      inset: 0;

      width: 8px;
      height: 1.20px;
      margin: auto;
      padding: 0;

      background-color: var(--close-red-icon);
      border-radius: 1px;
    }

    &::before {
      transform: rotate(45deg);
    }

    &::after {
      transform: rotate(-45deg);
    }
  }

  &:active {
    background-color: var(--close-red-active);
  }
}
