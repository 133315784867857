@use "../styles/utils";

@import "../styles/base/toast";

.container {
  @extend %toast;

  @include utils.layout("regular") {
    /*
     * Be wary of using percentages here.
     * In Safari, it can cause jankiness when the alert appears in narrow screens.
     */
    width: clamp(256px, 50ch, 100%);
  }

  @include utils.layout("compact") {
    width: 240px;
  }

  position: relative;

  display: flex;
  flex-direction: column;
  gap: var(--spacing-4px);
  align-items: center;

  padding: var(--spacing-12px);

  border-radius: var(--radius-12px);
  box-shadow: var(--elevation-1), var(--elevation-1);

  transition: color 150ms, background-color 150ms, border 150ms;
}

.title,
.description {
  font: var(--text-xs-bold);
}

.titleContainer {
  display: flex;
  gap: var(--spacing-5px);
  align-items: center;

  // Prevent layout shift when switching between visible/hidden title
  min-height: 20px;
}

.closeButton {
  position: absolute;
  top: var(--spacing-8px);
  right: var(--spacing-8px);
}

.icon {
  width: 16px;
  height: 16px;
}
