@use "../styles/utils";

$styles: ("brand", "positive", "alert", "destructive");
$weights: (
  "light": "secondary",
  "heavy": "primary"
);

@each $style in $styles {
  @each $weight, $hierarchy in $weights {
    .#{$style}.#{$weight} {
      --featured-icon-colour: var(
        --icon-on-#{$style}-#{$hierarchy}-primary-default
      );
      --featured-icon-background: var(
        --surface-#{$style}-#{$hierarchy}-default,
        var(--surface-#{$style}-#{$hierarchy}-default-fill)
      );
    }
  }
}

.xs {
  --featured-icon-container-size: #{utils.rem(32)};
  --featured-icon-size: #{utils.rem(20)};
}

.sm {
  --featured-icon-container-size: #{utils.rem(40)};
  --featured-icon-size: #{utils.rem(28)};
}

.md {
  --featured-icon-container-size: #{utils.rem(48)};
  --featured-icon-size: #{utils.rem(32)};
}

.lg {
  --featured-icon-container-size: #{utils.rem(64)};
  --featured-icon-size: #{utils.rem(40)};
}

.xl {
  --featured-icon-container-size: #{utils.rem(96)};
  --featured-icon-size: #{utils.rem(56)};
}

.container {
  display: flex;
  align-items: center;
  justify-content: center;

  min-width: var(--featured-icon-container-size);
  max-width: var(--featured-icon-container-size);
  min-height: var(--featured-icon-container-size);
  max-height: var(--featured-icon-container-size);

  color: var(--featured-icon-colour);

  background-color: var(--featured-icon-background);
  border-radius: 50%;
}

.icon {
  min-width: var(--featured-icon-size);
  max-width: var(--featured-icon-size);
  min-height: var(--featured-icon-size);
  max-height: var(--featured-icon-size);
}
