:root {
  --outline: var(--stroke-weight-2px) solid
    var(--surface-neutral-primary-focus-stroke, var(--tt-colour-focus-visible));
}

* {
  /*
   * This sets the color of the "highlight" that appears over an interactive
   * element while being tapped on mobile. Reasons for making it transparent:
   *
   * - The highlight's shape is square, which looks awful in the case of rounded
   *   buttons, taking away from the "app" feel
   *
   * - We already have our own active styles which clash with the default blue
   *   highlight colour
  */
  -webkit-tap-highlight-color: transparent;

  &:focus-visible {
    outline: var(--outline);
  }

  @media (prefers-reduced-motion: reduce) {
    transition: none !important;
    animation: none !important;
  }
}
