@use "../styles/utils";
@use "../styles/breakpoints.module.scss";

.drawer {
  @include utils.motion(reduce) {
    transition: none;
  }

  overflow-x: hidden;
  display: flex;
  flex-direction: column;
  flex-shrink: 0;

  width: 0;

  visibility: hidden;
  background: var(--surface-neutral-secondary-default-fill);

  transition: width var(--drawer-transition-duration) ease-out,
    visibility var(--drawer-transition-duration) ease-out;

  &.none {
    border: none;
  }

  &.left {
    border-left: var(--stroke-weight-1px) solid
    var(--surface-neutral-secondary-default-stroke);
  }

  &.open {
    @include utils.motion(reduce) {
      transition: none;
    }

    width: var(--drawer-width);
    visibility: visible;
    transition: width var(--drawer-transition-duration) ease-in;

    @media (width <= #{breakpoints.$sm}) {
      width: 100%;
      transition: none;
    }
  }

  @media (width <= #{breakpoints.$sm}) {
    transition: none;
  }
}

.regular {
  --drawer-width: clamp(#{utils.rem(240)}, 16%, #{utils.rem(304)});
}

.large {
  --drawer-width: clamp(#{utils.rem(304)}, 24%, #{utils.rem(344)});
}

.body {
  overflow: hidden;
  display: flex;
  flex-direction: column;

  // Prevent squashing when the drawer is closed
  min-width: var(--drawer-width);
  height: 100%;
}
