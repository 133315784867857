%representation {
  display: inline;
  font: var(--text-xs-semibold);
  word-break: break-word;

}

%outlined {
  padding: var(--spacing-2px) var(--spacing-6px);
  border-radius: var(--radius-8px);

  /*
  * This makes it so there are rounded edges on each of the text lines
  * (not just start and end of the text)
  */
  box-decoration-break: clone;
}

.input {
  &.default {
    @extend %representation;
  }

  &.outlined {
    @extend %representation;
    @extend %outlined;

    color: var(--text-on-brand-secondary-primary-default);
    background: var(--surface-brand-secondary-default);
  }
}

.output {
  &.default {
    @extend %representation;
  }

  &.outlined {
    @extend %representation;
    @extend %outlined;

    color: var(--text-on-positive-secondary-primary-default);
    background: var(--surface-positive-secondary-default);
  }
}
