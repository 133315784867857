.header {
  position: relative;

  display: flex;
  flex-direction: column;
  gap: var(--spacing-8px);

  padding: var(--spacing-12px);

  border-bottom: var(--stroke-weight-1px) solid
    var(--surface-neutral-primary-default-stroke);
}

.headerInner {
  display: flex;
  justify-content: space-between;
}

.title {
  width: 100%;
  font: var(--text-sm-bold);
}

.button {
  position: absolute;
  right: 0;
}
