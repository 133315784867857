.wrapper {
  position: relative;

  display: inline-flex;
  flex-direction: column;
  gap: 6px;
  align-items: center;
  justify-content: flex-end;

  padding: var(--spacing-8px) 0 var(--spacing-12px);
}
