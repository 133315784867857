.container {
  overflow-y: auto;
  display: flex;
  flex-direction: column;
  align-items: stretch;
  justify-content: center;

  height: 100%;
}

.addButton {
  width: 100%;
}
