:root {
  --window-controls-bar-height: 32px;
}

.controlsBar {
  display: flex;
  align-items: center;
  justify-content: flex-end;

  padding-right: var(--spacing-10px);

  // Allow moving the window by dragging window controls bar in Desktop
  -webkit-app-region: drag;

  &.brand {
    background: var(--surface-brand-secondary-default-fill);
  }

  &.neutral {
    background: none;
  }

  // Window controls bar buttons should not be draggable
  & button {
    -webkit-app-region: no-drag;
  }

  &.none {
    display: none;
  }

  &.regular {
    min-height: var(--window-controls-bar-height-regular);
  }

  &.large {
    min-height: var(--window-controls-bar-height-large);
  }
}
