.sm {
  --toggle-blob-size: 16px;
  --toggle-container-width: 35px;
  --toggle-container-height: 22px;
}

.md {
  --toggle-blob-size: 20px;
  --toggle-container-width: 43px;
  --toggle-container-height: 26px;
}

.container {
  cursor: pointer;

  position: relative;

  display: flex;
  align-items: center;

  width: var(--toggle-container-width);
  height: var(--toggle-container-height);

  background: var(--surface-neutral-secondary-default-fill);
  border-radius: 100px;
  box-shadow: inset var(--elevation-1);

  transition: background-color 0.3s;

  &:focus-within {
    outline: var(--outline);
  }

  &:hover {
    background: var(--surface-neutral-secondary-hover);
  }

  &.active {
    background: var(--surface-brand-primary-default);

    &:hover {
      background: var(--surface-brand-primary-hover);
    }
  }
}

.blob {
  --edge-offset: 3px;
  --hover-offset: 2px;

  position: absolute;
  left: var(--edge-offset);

  align-items: center;
  justify-content: center;

  width: var(--toggle-blob-size);
  height: var(--toggle-blob-size);

  background: var(--surface-neutral-primary-default-fill);
  border-radius: 50%;
  box-shadow: var(--elevation-1);

  transition: 0.3s;

  .container:hover & {
    transform: translate(var(--hover-offset));
  }

  .container.active & {
    transform: translate(calc(100% - var(--edge-offset)));
  }

  .container.active:hover & {
    transform: translate(calc(100% - var(--edge-offset) - var(--hover-offset)));
  }
}
