@use "../styles/base/surface";
@use "../styles/base/list";

.container {
  overflow-y: auto;
  display: flex;
  flex-direction: column;

  min-inline-size: auto;
  height: 100%;
  padding: 12px;

  border: none;
}

.list {
  @extend %list-with-radio-input;
  @extend %list;
  @extend %surface;
}
