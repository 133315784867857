.header {
  display: flex;
  flex-direction: column;
  width: 100%;

  &.default {
    background-color: var(--surface-neutral-secondary-default-fill);
    border-bottom: var(--stroke-weight-1px) solid
      var(--surface-neutral-primary-default-stroke);
  }

  &.transparent {
    background-color: transparent;
  }
}
