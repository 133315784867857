@use "../../styles/utils";

$colours: ("neutral", "brand", "positive", "alert", "destructive");
$hierarchies: ("primary", "secondary", "tertiary", "link", "link-invert");

@each $colour in $colours {
  @each $hierarchy in $hierarchies {
    .#{$colour}.#{$hierarchy} {
      --button-color: var(--text-on-#{$colour}-#{$hierarchy}-primary-default);
      --button-background: var(
        --surface-#{$colour}-#{$hierarchy}-default-fill,
        var(--surface-#{$colour}-#{$hierarchy}-default)
      );
      --button-cursor: pointer;

      &:hover:not(:disabled, [aria-disabled="true"]) {
        --button-background: var(--surface-#{$colour}-#{$hierarchy}-hover);
      }

      &:disabled,
      &[aria-disabled="true"] {
        --button-cursor: default;
        --button-color: var(--text-on-#{$colour}-secondary-primary-disabled);
        --button-background: var(--surface-#{$colour}-#{$hierarchy}-disabled);
      }

      @if $hierarchy == "tertiary" {
        --button-color: var(--text-on-#{$colour}-secondary-primary-default);
        --button-background: var(--surface-neutral-primary-default-fill);

        &:hover:not(:disabled, [aria-disabled="true"]) {
          --button-background: var(
            --surface-#{$colour}-secondary-default,
            var(--surface-#{$colour}-secondary-default-fill)
          );
        }
      }

      @if $colour == "neutral" and ($hierarchy == "tertiary" or $hierarchy == "secondary") {
        &:not(:disabled, .iconOnly) {
          --button-border-color: var(--surface-neutral-primary-default-stroke);
        }
      }

      @if $hierarchy == "link" or $hierarchy == "link-invert" {
        --button-height: unset;
        --button-padding: var(--spacing-0px);
        --button-text-decoration: underline;
        --button-background: none;

        &:disabled {
          --button-color: var(--text-on-#{$colour}-primary-primary-disabled);
        }

        &.iconOnly {
          --button-width: unset;
        }
      }

      @if $hierarchy == "link" {
        --button-color: var(--text-on-#{$colour}-secondary-primary-default);

        &:hover:not(:disabled, [aria-disabled="true"]) {
          --button-color: var(--text-on-#{$colour}-secondary-primary-hover);
        }
      }

      @if $hierarchy == "link-invert" {
        --button-color: var(--text-on-#{$colour}-primary-primary-default);

        &:hover:not(:disabled, [aria-disabled="true"]) {
          --button-color: var(--text-on-#{$colour}-primary-primary-hover);
        }
      }

      @if $colour == "neutral" {
        --button-color: var(--text-on-neutral-primary-secondary-default);

        @if $hierarchy == "secondary" {
          --button-color: var(--text-on-neutral-primary-primary-default);
          --button-background: var(--surface-neutral-primary-default-fill);

          &:hover:not(:disabled, [aria-disabled="true"]) {
            --button-color: var(--text-on-neutral-primary-primary-hover);
          }
        }

        @if $hierarchy != "link" {
          &:hover:not(:disabled, [aria-disabled="true"]) {
            --button-background: var(--surface-neutral-primary-hover);
          }
        }
      }
    }
  }
}

// Button to font size relation is not 1-1
$button-to-font-size: (
  "xs": "xs",
  "sm": "sm",
  "md": "sm",
  "lg": "md",
  "xl": "lg"
);

@each $size, $font-size in $button-to-font-size {
  .#{$size} {
    --button-font: var(--text-#{$font-size}-bold);
  }
}

.xs {
  --button-icon-size: #{utils.rem(16)};
  --button-gap: var(--spacing-4px);
  --button-height: #{utils.rem(32)};
  --button-padding: var(--spacing-0px) var(--spacing-8px);

  &.iconOnly {
    --button-width: #{utils.rem(32)};
  }
}

.sm {
  --button-icon-size: #{utils.rem(20)};
  --button-gap: var(--spacing-4px);
  --button-height: #{utils.rem(36)};
  --button-padding: var(--spacing-0px) var(--spacing-8px);

  &.iconOnly {
    --button-width: #{utils.rem(36)};
  }
}

.md {
  --button-icon-size: #{utils.rem(24)};
  --button-gap: var(--spacing-6px);
  --button-height: #{utils.rem(40)};
  --button-padding: var(--spacing-0px) var(--spacing-12px);

  &.iconOnly {
    --button-width: #{utils.rem(40)};
  }
}

.lg {
  --button-icon-size: #{utils.rem(24)};
  --button-gap: var(--spacing-6px);
  --button-height: #{utils.rem(48)};
  --button-padding: var(--spacing-0px) var(--spacing-16px);

  &.iconOnly {
    --button-width: #{utils.rem(48)};
  }
}

.xl {
  --button-icon-size: #{utils.rem(24)};
  --button-gap: var(--spacing-6px);
  --button-height: #{utils.rem(56)};
  --button-padding: var(--spacing-0px) var(--spacing-24px);

  &.iconOnly {
    --button-width: #{utils.rem(56)};
  }
}

.iconOnly {
  --button-padding: 0;
}

%icon {
  display: flex;
  align-items: center;
  justify-content: center;

  width: var(--button-icon-size);
  height: var(--button-icon-size);
}

.trailingIcon {
  @extend %icon;

  opacity: 0.5;
}

.mainIcon {
  @extend %icon;
}

%button {
  cursor: var(--button-cursor);
  user-select: none;

  display: inline-flex;
  flex-shrink: 0;
  gap: var(--button-gap);
  align-items: center;
  justify-content: center;

  width: var(--button-width);
  height: var(--button-height);
  padding: var(--button-padding);

  font: var(--button-font);
  color: var(--button-color);
  text-decoration: var(--button-text-decoration);

  background: var(--button-background);
  border: 1px solid;
  border-color: var(--button-border-color, transparent);
  border-radius: var(--radius-8px);
  outline-offset: 2px;

  transition:
    background 250ms,
    color 250ms,
    border-color 250ms;
}

.button {
  @extend %button;
}
