@use "../styles/utils";

html {
  /*
   * Disables the touchpad overscroll 'bounce' effect which can otherwise be
   * disruptive to the web app experience (e.g. causes elements to grow/shrink
   * beyond their intended size).
   */
  @media all and (display-mode: standalone) {
    overscroll-behavior: none;
  }
}

body {
  @include utils.layout("compact") {
    width: max-content;
  }

  @include utils.theme("light") {
    color-scheme: light;
  }

  @include utils.theme("dark") {
    color-scheme: dark;
  }

  // Prevent unnecessary vertical scrollbar at extra small screen size
  overflow: hidden;

  /*
   * This ensures the UI does not get stuck in phone notches!
   */
  padding: env(safe-area-inset-top) env(safe-area-inset-right)
    env(safe-area-inset-bottom) env(safe-area-inset-left);

  font-family: "Plus Jakarta Sans", Helvetica, Arial, sans-serif;

  // Antialiasing causes the font to be rendered more similarly to the designs
  -webkit-font-smoothing: antialiased;
  -moz-osx-font-smoothing: grayscale;
  color: var(--text-on-neutral-primary-primary-default);

  background-color: var(--surface-neutral-primary-default-fill);
}
