%header {
  display: flex;
  gap: var(--spacing-8px);
  align-items: center;
  padding: 0 var(--spacing-24px);
}

.standardHeader {
  @extend %header;

  justify-content: space-between;
  min-height: 60px;
  border-bottom: var(--stroke-weight-1px) solid
    var(--surface-neutral-primary-default-stroke);
}

.compactHeader {
  @extend %header;

  min-height: 40px;
  padding-top: var(--spacing-24px);

  & .closeIcon {
    position: absolute;
    top: var(--spacing-24px);
    right: var(--spacing-24px);
  }
}

.title {
  cursor: default;
  user-select: none;
}
