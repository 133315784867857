%list-item {
  position: relative;

  display: flex;

  margin-bottom: 1px;

  border: 1px solid transparent;
  border-radius: 8px;

  &:not(:last-child, :has(:focus-visible))::after {
    content: "";

    position: absolute;
    right: 8px;
    bottom: -2px;
    left: 8px;

    display: block;

    height: 1px;

    background-color: var(--surface-neutral-primary-default-stroke);
  }
}

%list-item-with-hover {
  &:hover {
    background-color: var(--surface-neutral-primary-hover);
  }
}
