.container {
  display: flex;
  flex-direction: column;
  align-items: center;
  align-self: stretch;

  padding: var(--spacing-16px) var(--spacing-12px);

  font: var(--text-xs-medium);
  color: var(--text-on-neutral-primary-secondary-default);
  text-align: center;
}

.term {
  font: var(--text-xs-bold);
  word-break: break-word;
}
