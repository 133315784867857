.button {
  user-select: none;

  display: flex;
  flex-direction: column;
  gap: var(--spacing-4px);

  padding: var(--spacing-8px) var(--spacing-6px) var(--spacing-8px) var(--spacing-12px);

  background: var(--surface-neutral-primary-default-fill);
  border: 1px solid transparent;
  border-radius: var(--radius-8px);

  transition: background 250ms, color 250ms, border-color 250ms;

  &:disabled {
    cursor: default;
  }

  &:hover {
    background: var(--surface-neutral-primary-hover);
    border-color: var(--surface-neutral-primary-default-stroke);
  }

  &:active {
    background-color: var(--surface-brand-secondary-default);
    border-color: var(--surface-brand-secondary-default-stroke);
  }
}

.icon {
  width: 20px;
  height: 20px;
  color: var(--icon-on-brand-secondary-primary-default);
}

.title {
  display: flex;
  gap: var(--spacing-8px);
  font: var(--text-sm-semibold);
  color: var(--text-on-neutral-primary-primary-default);
}

.description {
  font: var(--text-xs-medium);
  color: var(--text-on-neutral-primary-secondary-default);
  text-align: left;
}
