@use "sass:list";
@use "../../styles/base/popover";
@use "../../styles/base/surface";
@use "../widths.scss";

.container {
  position: relative;

  @container formatting (width > #{list.nth(widths.$widths, list.length(widths.$widths))}) {
    display: none;
  }
}

.popover {
  @extend %popover;
  @extend %surface;

  flex-direction: column;
  gap: var(--spacing-2px);

  margin-top: 4px;
  padding: var(--spacing-4px);

  border: var(--stroke-weight-1px) solid
  var(--surface-neutral-primary-default-stroke);
}

.item {
  cursor: pointer;

  display: flex;
  flex-shrink: 0;
  align-items: center;
  justify-content: space-between;

  min-height: 40px;
  padding: var(--spacing-8px) 6px var(--spacing-8px) var(--spacing-12px);

  font: var(--text-sm-regular);

  background: var(--surface-neutral-primary-default-fill);
  border: var(--stroke-weight-1px) solid transparent;
  border-radius: var(--radius-8px);

  transition:
    background 0.25s,
    color 0.25s,
    border-color 0.25s;

  @for $i from 1 through list.length(widths.$widths) {
    @container formatting (width > #{list.nth(widths.$widths, $i) + 1}) {
      &:nth-of-type(#{$i}) {
        display: none;
      }
    }
  }

  &:has(:disabled) {
    cursor: default;
    color: var(--icon-on-alert-secondary-primary-disabled);
    background: var(--surface-neutral-primary-disabled);
  }

  &:empty {
    display: none;
  }

  &:has(input:focus-visible, button:focus-visible) {
    outline: var(--outline);
  }

  &:hover:not(:has(:disabled)) {
    background: var(--surface-neutral-primary-hover);
    border-color: var(--surface-neutral-primary-default-stroke);
  }
}
