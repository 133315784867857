@use "../../styles/base/user-icon.scss";

.userIcon {
  @extend %user-icon;

  display: flex;
  gap: var(--spacing-8px);
  align-items: center;
  justify-content: center;

  padding: var(--spacing-8px);

  color: var(--text-on-neutral-primary-primary-default);

  &.neutral {
    background-color: var(--surface-neutral-primary-default-fill);
  }

  &.none {
    background: none;
  }
}

.large {
  width: 32px;
  height: 32px;
  font: var(--text-sm-semibold);
}

.small {
  width: 24px;
  height: 24px;
  font: var(--text-xs-semibold);
}
