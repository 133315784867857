/* stylelint-disable property-no-unknown */
/* stylelint-disable selector-pseudo-class-no-unknown */

$xxs: 320px; // Extra extra small devices (phones)
$xs: 480px; // Extra small devices (phones)
$sm: 768px; // Small devices (tablets)
$md: 1024px; // Medium devices (laptops)
$lg: 1200px; // Large devices (laptops)
$xl: 1440px; // Extra large devices (desktops)

:export {
  lg: $lg;
  md: $md;
  sm: $sm;
  xl: $xl;
  xs: $xs;
  xxs: $xxs;
}
