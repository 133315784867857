$colours: ("neutral", "brand");

.button {
  display: flex;
  align-items: center;
  justify-content: center;

  width: 46px;
  height: 32px;

  border: var(--stroke-weight-1px) solid transparent;
  border-radius: var(--radius-8px);

  transition: background 250ms, color 250ms;

  @each $colour in $colours {
    @if $colour == "neutral" {
      &.#{$colour} {
        color: var(--icon-on-neutral-primary-secondary-default);
      }

      &:hover {
        &.#{$colour} {
          background-color: var(--surface-neutral-primary-hover);
          border: var(--stroke-weight-1px)
            solid
            var(--surface-neutral-primary-default-stroke);
        }
      }
    } @else if $colour == "brand" {
      &.#{$colour} {
        color: var(--icon-on-brand-primary-primary-default);
      }

      &:hover {
        &.#{$colour} {
          background-color: var(--surface-brand-primary-hover);
          border: var(--stroke-weight-1px)
            solid
            var(--surface-brand-primary-default-stroke);
        }
      }
    }
  }
}
