@use "../styles/base/popover";
@use "../styles/base/surface";

.container {
  position: absolute;
  z-index: 2;
  top: 8px;
  left: 16px;

  max-height: 34px;
}

.itemsSurface {
  @extend %popover;
  @extend %surface;

  z-index: 1;
  transform: translateY(var(--spacing-4px));

  min-width: 225px;
  padding: var(--spacing-4px);

  border: var(--stroke-weight-1px) solid
  var(--surface-neutral-primary-default-stroke);
}

.items {
  display: flex;
  flex-direction: column;
  gap: var(--spacing-2px);
  list-style: none;
}
