.container {
  display: flex;
  flex-direction: row;
  gap: var(--spacing-4px);
  align-items: flex-end;

  padding: var(--spacing-8px) var(--spacing-8px) var(--spacing-10px)
    var(--spacing-12px);

  color: var(--text-on-neutral-primary-secondary-default);

  background: var(--surface-neutral-primary-default-fill);
  border-radius: var(--radius-8px);
  box-shadow: var(--elevation-3);
}

.column {
  display: flex;
  flex-direction: column;
  gap: var(--spacing-4px);
}

.title {
  font: var(--text-xs-medium);
}

.icon {
  width: 16px;
  height: 24px;
  color: var(--icon-on-neutral-primary-secondary-default);
}
