@keyframes slide-up {
  from {
    // `translate3d` used for hardware acceleration = smoother transition
    transform: translate3d(0, 60px, 0);
  }
}

@keyframes shrink {
  to {
    transform: scale(0.85);
  }
}

@keyframes fade-in {
  from {
    opacity: 0;
  }

  to {
    opacity: 1;
  }
}

@keyframes fade-out {
  to {
    opacity: 0;
  }
}

@keyframes blur-in {
  to {
    backdrop-filter: saturate(180%) blur(2px) invert(15%);
  }
}

@keyframes blur-out {
  from {
    backdrop-filter: saturate(180%) blur(2px) invert(15%);
  }

  to {
    backdrop-filter: none;
  }
}

.modal {
  $open-duration: 300ms;
  $close-duration: 150ms;

  min-height: 200px;
  margin: auto;
  border: none;
  animation: fade-out $close-duration forwards, shrink $close-duration forwards;

  &::backdrop {
    animation: fade-out $close-duration forwards,
      blur-out $close-duration forwards;
  }

  /**
   * When our <dialog> closes, this can get in the way of our exit animations:
   *
   * - It changes to display: none
   * - It's removed from the top layer
   *
   * Setting this transition allows us to delay the removal of our <dialog>
   * from the top layer, which allows our exit animations to run without being
   * interrupted.
   *
   * Note: `overlay` in top layer exit transitions are not supported yet by Safari,
   * so we can only enable it for Chrome-like browsers. see:
   * {@link https://bugs.webkit.org/show_bug.cgi?id=276727}.
   *
   * For more info on animating <dialog> see
   * {@link https://frontendmasters.com/blog/animating-dialog/}.
   */
  &.enableExitTransition {
    transition: display $close-duration allow-discrete,
      overlay $close-duration allow-discrete;
  }

  &[open] {
    animation: fade-in $open-duration forwards, slide-up $open-duration forwards;

    &::backdrop {
      animation: fade-in $open-duration forwards,
        blur-in $open-duration forwards;
    }
  }

  @media (prefers-reduced-motion: prefers) {
    animation: fade-out $close-duration forwards;

    &::backdrop {
      animation: fade-out $close-duration forwards;
    }

    &[open] {
      animation: fade-in $open-duration forwards;

      &::backdrop {
        animation: fade-in $open-duration forwards;
      }
    }
  }
}
