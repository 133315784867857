@use "../../../styles/utils.scss";

@keyframes slideDown {
  to {
    transform: translateY(0);
  }
}

.container {
  user-select: none;

  display: grid;
  grid-template-columns: [cancel-button] min-content [app-icon] min-content [gap] 10px [explanation] auto;
  align-items: center;

  padding: 12px 12px 12px 0;

  font-family: system-ui;

  background-color: var(--pwa-install-prompt-container-background-colour);
  border-bottom: 1px solid var(--pwa-install-prompt-border-colour);

  &.slideIn {
    transform: translateY(-100%);
    animation: slideDown 0.35s ease-out 0.75s forwards;

    @media (prefers-reduced-motion: reduce) {
      transform: none;
    }
  }
}

.cancelButton {
  grid-area: cancel-button;

  width: 40px;
  height: 40px;

  font-weight: 400;
  color: var(--apple-instruction-cancel-button-colour);

  border: none;

  & svg {
    width: 16px;
    height: 16px;
  }
}

.appIcon {
  display: flex;
  grid-area: app-icon;
  width: 56px;
  height: 56px;

  & svg {
    width: 100%;
    height: 100%;
  }
}

.explanation {
  display: grid;
  grid-area: explanation;
  grid-template-rows: [app-name] min-content [add-to-homescreen] min-content [gap] 4px [call-to-action] min-content;
  justify-content: flex-start;
}

.appName {
  grid-area: app-name;

  font-size: utils.rem(15);
  font-weight: 400;
  line-height: utils.rem(17);
  color: var(--pwa-install-prompt-app-name-text-colour);
  letter-spacing: 0.04em;
}

.addToHomeScreen {
  grid-area: add-to-homescreen;

  font-size: utils.rem(13);
  font-weight: 400;
  line-height: utils.rem(17);
  color: var(--apple-instruction-add-to-home-screen-text-colour);
  letter-spacing: 0.05em;
}

.exportIcon {
  display: inline-block;

  width: 16px;
  height: 16px;
  margin-right: 1px;

  color: var(--apple-instruction-export-icon-colour);
  text-align: center;

  & svg {
    position: relative;
    top: 2px;
    width: 100%;
    height: 100%;
  }
}

.callToAction {
  grid-area: call-to-action;

  font-size: utils.rem(13);
  font-weight: 400;
  line-height: utils.rem(16.78);
  color: var(--apple-instruction-call-to-action-background-colour);

  & strong {
    font-weight: 700;
    white-space: nowrap;
  }
}
