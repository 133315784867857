@use "../styles/utils";

.button {
  display: flex;
  flex-shrink: 0;
  align-items: center;
  justify-content: center;

  width: utils.rem(60);
  height: utils.rem(60);
  padding: var(--spacing-4px);

  font-size: 1.2rem;
  color: white;

  border: none;
  border-radius: 50%;
  box-shadow: var(--elevation-3);

  transition: background-color 200ms 50ms ease-out;

  &:focus-visible {
    outline-offset: 2px;
  }
}

.dictating {
  background: var(--surface-destructive-primary-hover);

  &:hover {
    background: var(--surface-destructive-primary-hover);
  }
}

.notDictating,
.loading {
  background: var(--surface-brand-primary-hover);

  &:hover {
    background: var(--surface-brand-primary-hover);
  }
}

.inner {
  width: 100%;
  height: 100%;
  padding: 10px;

  background: var(--dictation-button-background);
  border-radius: 50%;
  box-shadow: var(--elevation-3);

  transition: background-color 200ms ease-out;

  .dictating & {
    --dictation-button-background: var(
      --surface-destructive-primary-default-fill
    );

    background-color: var(
      --surface-destructive-primary-default-fill
    );

    &:hover {
      --dictation-button-background: var(--surface-destructive-primary-hover);

      background-color: var(--surface-destructive-primary-hover);
    }
  }

  .notDictating &,
  .loading & {
    --dictation-button-background: var(--surface-brand-primary-default);

    background-color: var(--surface-brand-primary-default);

    &:hover {
      --dictation-button-background: var(--surface-brand-primary-hover);

      background-color: var(--surface-brand-primary-hover);
    }
  }
}
