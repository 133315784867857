/* stylelint-disable scss/at-rule-no-unknown */
/* stylelint-disable selector-pseudo-class-no-unknown */
@use "../styles/utils";

.tooltip {
  $duration: 200ms;
  $delay: 500ms;

  // Minimal delay for touch users, 500ms is a long time to be press holding
  @include utils.isTouch {
    &:popover-open {
      transition-delay: 0s;
      transition-duration: 50ms;
    }
  }

  /*
   * On mobile users can long press to trigger the tooltip showing, which can
   * then end up with the tooltip's text being selected - not ideal.
   */
  user-select: none;
  opacity: 0;
  border: none;
  transition: opacity $duration, display $duration allow-discrete,
    overlay $duration allow-discrete;

  &.confirming {
    transition-delay: 0s;

    &:popover-open {
      transition-delay: 0s;
    }
  }

  &:popover-open {
    opacity: 1;
    transition: opacity $duration $delay,
      display $duration $delay allow-discrete,
      overlay $duration $delay allow-discrete;
  }

  @starting-style {
    &:popover-open {
      opacity: 0;
    }
  }
}

.placementBottom {
  padding-top: 4px;
}

.placementLeft {
  padding-right: 4px;
}

.placementTop {
  padding-bottom: 4px;
}

.placementRight {
  padding-left: 4px;
}

.contents {
  position: relative;

  display: flex;
  flex-direction: column;
  align-items: center;
  justify-content: center;

  padding: var(--spacing-6px) var(--spacing-12px);

  color: var(--text-on-invert-primary-primary-default);
  text-align: center;
  white-space: nowrap;

  background-color: var(--surface-invert-primary-default-fill);
  border-radius: var(--radius-8px);
  box-shadow: var(--elevation-1);

  transition: background-color 0s 0.2s;

  &.confirming {
    background-color: var(--surface-brand-primary-default);
    transition: 0s;
  }
}

.wrapper {
  position: relative;
}

.title {
  font: var(--text-xs-bold);
}

.shortcut {
  font: var(--text-xs-regular);
}
