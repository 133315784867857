@use "../styles/base/form";

.form {
  @extend %form;

  overflow: auto;
  flex: 1;
  gap: var(--spacing-20px);
  align-items: normal;
  justify-content: normal;

  max-height: 700px;
}

.radioGroup {
  display: grid;
  grid-template-columns: repeat(3, 1fr);
  gap: var(--spacing-4px);

  appearance: none;
  border: none;
}
