@use "../../styles/utils";

.editor {
  flex: 1;

  width: 100%;
  min-height: 100%;
  padding: var(--spacing-12px)
    /**
     * Calculate side padding as half the remaining available space with the
     * editor width fixed at 750px.
     */
    min(
      calc((100% - 750px) * 0.5) /* Make the minimum width 750px */,

      calc((100% - var(--editor-font-size) * 37.5px) * 0.5)
        /* Allow the width to expand with larger font sizes */
    );

  font: var(--text-md-regular);
  font-family: var(--editor-font-family);
  font-size: calc(var(--editor-font-size) * 1px);

  &:focus-visible {
    outline: none;
  }
}

.inProgress {
  &:active {
    caret-color: transparent;
  }
}
