/**
 * Theme mixin
 *
 * Takes a colour scheme, e.g. "dark"|"light" and creates a media query with
 * prefers-color-scheme and a descendant selector with the scheme as a class
 * name.
 *
 * @example
 * // input:
 * @include theme(dark) { ... style rules ... }
 *
 * // output:
 * @media (prefers-color-scheme: dark) { ... style rules ... }
 * body.dark { ... style rules ... }
 */
 @mixin theme($schemes...) {
  @each $scheme in $schemes {
    .#{$scheme},
    &.#{$scheme} {
      @content;
    }

    @media (prefers-color-scheme: $scheme) {
      @content;
    }
  }
}

/**
 * Motion mixin
 *
 * Takes a motion preference, e.g. "reduce"|"no-preference".
 *
 * Allows styling elements to respond to the user's motion preference.
 * This is done via a media query.
 *
 * Likewise, the styling will also be applied to elements or descendants of an
 * element with the class name matching the motion preference. This is handy
 * when it comes to, say, pausing transitions temporarily: a `.reduce` class
 * can be added to an element high in the DOM tree, and any descendants using the
 * mixin may be styled accordingly with `transition: none`.
 */
@mixin motion($preference) {
  &:global(.#{$preference}),
  :global(.#{$preference}) & {
    @content;
  }

  @media (prefers-reduced-motion: $preference) {
    @content;
  }
}

/**
 * Layout mixin
 *
 * Applies styles to elements when the layout is specified.
 * Takes a layout name, e.g. "regular"|"compact".
 *
 * @example
 *
 * // input:
 * @include layout("compact") { ... style rules ... }
 *
 * // output:
 * &.compact,
 * & .compact { ... style rules ... }
 *
 * // explanation
 * first selector: element with the layout class
 * second selector: descendant of an element with the layout class
 */
@mixin layout($layout) {
  &:global(.#{$layout}),
  :global(.#{$layout}) & {
    @content;
  }
}

/**
 * Applies styles to elements when the device does not primarily use a fine
 * pointer. This approach ensures that users with both a touch screen and a
 * mouse can still have hover-based interactions.
 */
@mixin isTouch {
  @media (pointer: coarse), (pointer: none) {
    @content;
  }
}

@mixin visuallyHidden {
  user-select: none;

  position: absolute;

  overflow: hidden !important;

  width: 1px;
  height: 1px;

  clip-path: polygon(0 0, 0 0, 0 0);
}

@function rem($pixelValue) {
  @return $pixelValue * 0.0625rem;
}

/**
 * Used to set the font-size, font-weight and other properties
 * for a text element based on the level and size (determined by variables).
 *
 * Additional properties can be passed in the $properties list and they will
 * be set with the same value as font-size.
 *
 * @param {string} $level - The level of the text element.
 * @param {string} $size - The size of the text element.
 * @param {list} $properties - A list of additional properties to be set on
 * the text element. Defaults to an empty list.
 *
 * @example
 * // input:
 * @include text('title', 'large', ('line-height', 'height'));
 *
 * // output:
 * font-size: var(--tt-size-title-large);
 * font-weight: var(--tt-weight-title);
 * line-height: var(--tt-size-title-large);
 * height: var(--tt-size-title-large);
 */
@mixin text($level, $size, $properties: ()) {
  font-size: var(--tt-size-#{$level}-#{$size});
  font-weight: var(--tt-weight-#{$level});

  @each $property in $properties {
    #{$property}: var(--tt-size-#{$level}-#{$size});
  }
}
