@use "../../styles/base/list";
@use "../../styles/base/list-item";
@use "../../styles/base/popover";
@use "../../styles/base/surface";

.container {
  position: relative;
  margin-right: 8px;
}

.popover {
  @extend %popover;

  padding-inline: 4px;
}

.popoverContents {
  @extend %surface;

  width: 225px;
}

.listingText {
  display: flex;
  gap: 8px;
  align-items: center;

  padding: 8px 6px 8px 12px;

  font: var(--text-sm-medium);
}

.icon {
  width: 20px;
  height: 20px;
}

.list {
  @extend %list;

  border: 1px solid var(--surface-neutral-primary-default-stroke);
}

.listItem {
  @extend %list-item-with-hover;
  @extend %list-item;

  &:hover {
    border: var(--stroke-weight-1px, 1px) solid var(--surface-neutral-primary-default-stroke);
  }
}
