@use "./utils.scss";

:root {
  --apple-instruction-export-icon-colour: #0a84ff;
  --native-install-button-text-colour: #1d192b;
  --native-install-button-background-colour: #7fd8fd;
  --native-install-button-hover-background-colour: #c6e6fc;
  --colour-neutrals-neutral-off-black: #1c1c1e;

  @include utils.theme(light, theme--light, theme--sepia) {
    --pwa-install-prompt-border-colour: #e2e2e2;
    --pwa-install-prompt-app-name-text-colour: #000;
    --pwa-install-prompt-container-background-colour: #fff;
    --apple-instruction-add-to-home-screen-text-colour: #596977;
    --apple-instruction-call-to-action-background-colour: #1a252f;
    --apple-instruction-cancel-button-colour: #2b3e4d;
    --native-instruction-cancel-button-colour: #000;
    --native-install-to-home-screen-text-colour: #000000bd;
  }

  @include utils.theme(dark, theme--dark) {
    --pwa-install-prompt-border-colour: #2d2d2d;
    --pwa-install-prompt-app-name-text-colour: #fff;
    --pwa-install-prompt-container-background-colour: var(
      --colour-neutrals-neutral-off-black
    );
    --apple-instruction-add-to-home-screen-text-colour: #bfc5ca;
    --apple-instruction-call-to-action-background-colour: #fff;
    --apple-instruction-cancel-button-colour: #eaecee;
    --native-instruction-cancel-button-colour: #ebebf5bd;
    --native-install-to-home-screen-text-colour: #ebebf5bd;
  }
}
