@use "../styles/base/form";

.form {
  @extend %form;
}

.buttonGroup {
  display: flex;
  gap: var(--spacing-8px);
}
