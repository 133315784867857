.radioGroup {
  display: grid;
  gap: var(--spacing-5px);
  appearance: none;
  border: none;
}

.legend {
  // For consistency with field labels
  cursor: default;
  font: var(--text-sm-semibold);
}

.inputs {
  display: flex;

  &.horizontal {
    flex-direction: row;
    gap: var(--spacing-4px);
  }

  &.vertical {
    flex-direction: column;
    gap: var(--spacing-2px);
  }

  & > * {
    // Child elements should expand and take up the space
    flex: 1;
  }
}
