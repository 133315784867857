.container {
  display: flex;
  gap: var(--spacing-12px);
  align-items: center;
  justify-content: space-between;

  padding: var(--spacing-4px) var(--spacing-8px);
}

.text {
  font: var(--text-sm-medium);
  font-variant-numeric: tabular-nums;
}

.button {
  outline-offset: 0;
}
