.container {
  position: relative;

  display: grid;
  grid-template-columns: 1fr;
  grid-template-rows: 1fr;
  place-items: center center;

  width: 100%;
  height: 100%;

  & > * {
    position: relative;

    grid-column: 1;
    grid-row: 1;

    width: 28px;
    height: 28px;

    visibility: hidden;
    opacity: 0;

    transition: visibility 50ms ease-out, opacity 50ms ease-out;
  }

  &.inactive .inactiveIcon {
    visibility: visible;
    opacity: 1;
  }

  &.loading .loadingIcon {
    visibility: visible;
    opacity: 1;
  }

  &.active {
    @media (prefers-reduced-motion: no-preference) {
      .miniSoundEqualiser {
        visibility: visible;
        opacity: 1;
      }
    }

    @media (prefers-reduced-motion: reduce) {
      .activeIcon {
        visibility: visible;
        opacity: 1;
      }
    }
  }
}

@keyframes spin {
  0% {
    opacity: 1;
  }

  100% {
    opacity: 0;
  }
}

.spinner {
  & path {
    animation: spin 1s steps(8) infinite;
  }

  & path:nth-child(1) {
    opacity: 1;
    animation-delay: 0s;
  }

  & path:nth-child(2) {
    opacity: 0.875;
    animation-delay: 0.125s;
  }

  & path:nth-child(3) {
    opacity: 0.75;
    animation-delay: 0.25s;
  }

  & path:nth-child(4) {
    opacity: 0.625;
    animation-delay: 0.375s;
  }

  & path:nth-child(5) {
    opacity: 0.5;
    animation-delay: 0.5s;
  }

  & path:nth-child(6) {
    opacity: 0.375;
    animation-delay: 0.625s;
  }

  & path:nth-child(7) {
    opacity: 0.25;
    animation-delay: 0.75s;
  }

  & path:nth-child(8) {
    opacity: 0.125;
    animation-delay: 0.875s;
  }
}
