@use "../../styles/utils.scss";

.icon,
.linkIndicator {
  width: 20px;
  height: 20px;
  color: var(--icon-on-neutral-primary-primary-default);
  transition: background 250ms, color 250ms, border-color 250ms;
}

.linkIndicator {
  margin-left: auto;
  color: var(--icon-on-neutral-primary-secondary-disabled);
}

.item {
  display: flex;
  gap: var(--spacing-8px);
  align-items: center;

  width: 100%;
  height: 40px;
  padding: 0 var(--spacing-12px);

  font-size: utils.rem(14);
  font-weight: 500;
  text-decoration: none;

  background-color: var(--surface-neutral-primary-default);
  border: 1px solid transparent;
  border-radius: var(--radius-8px);

  transition: background 250ms, color 250ms, border-color 250ms;

}

.item:not(.noInteraction) {
  &:hover {
    background-color: var(--surface-neutral-primary-hover);
    border-color: var(--surface-neutral-primary-default-stroke);
  }

  &:active {
    background-color: var(--surface-brand-secondary-default);
    border-color: var(--surface-brand-secondary-default-stroke);
  }

  &:hover,
  &:active {
    .linkIndicator {
      color: var(--icon-on-neutral-primary-secondary-default);
    }
  }
}

.noInteraction {
  cursor: default;
}

.withSeparator {
  position: relative;
  margin-bottom: 5px;

  &::after {
      content: "";

      position: absolute;
      right: var(--spacing-8px);
      bottom: -4px;
      left: var(--spacing-8px);

      display: block;

      height: 1px;

      background-color: var(--surface-neutral-primary-default-stroke);
  }
}

.shortcut {
  margin-left: auto;
  font: var(--text-xs-medium);
  color: var(--text-on-neutral-primary-secondary-default);
}
