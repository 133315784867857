@use "../styles/utils";

@import "../styles/base/toast";

.container {
  @extend %toast;

  @include utils.layout("regular") {
    /*
     * Be wary of using percentages here.
     * In Safari, it can cause jankiness when the alert appears in narrow screens.
     */
    width: clamp(256px, 50ch, 90vw);
  }

  @include utils.layout("compact") {
    width: 240px;
  }

  position: relative;

  display: flex;
  gap: var(--spacing-8px);
  align-items: flex-start;

  padding: var(--spacing-12px);

  border-radius: var(--radius-12px);
  box-shadow: var(--elevation-1), var(--elevation-1);

  transition: color 150ms, background-color 150ms, border 150ms;

  &.stacked {
    flex-direction: column;
    gap: var(--spacing-10px);
  }

  &.row {
    flex-direction: row;
    justify-content: space-between;
  }
}

.primaryText {
  font: var(--text-sm-bold);
}

.secondaryText {
  font: var(--text-sm-medium);
}

.titleContainer {
  display: flex;
  gap: var(--spacing-8px);
}

.closeButton {
  position: absolute;
  top: var(--spacing-8px);
  right: var(--spacing-8px);
}

.icon {
  width: 20px;
  height: 20px;
}

.labelButton {
  margin-left: auto;
}

.textContainer {
  display: flex;
  gap: var(--spacing-8px);

  &.stacked {
    flex-direction: column;
  }

  &.row {
    flex-direction: row;
  }
}
