@mixin active {
  color: var(--icon-on-neutral-secondary-primary-default);
  background: var(--surface-brand-secondary-default);
  border-color: var(--surface-brand-secondary-default-stroke);
}

%button {
  position: relative;

  display: flex;
  align-items: center;
  justify-content: center;

  width: 32px;
  height: 32px;

  color: var(--icon-on-neutral-primary-secondary-default);

  background: none;
  border: var(--stroke-weight-1px) solid transparent;
  border-radius: var(--radius-8px);

  &:not(:disabled) {
    &:hover {
      color: var(--icon-on-neutral-secondary-secondary-hover);
      background: var(--surface-neutral-secondary-hover);
    }

    &:active {
      @include active;
    }
  }

  &:disabled {
    cursor: default;
    color: var(--icon-on-alert-secondary-primary-disabled);
  }
}

%ellipse {
  content: "";

  position: absolute;
  top: var(--spacing-2px);
  right: var(--spacing-2px);
  transform: scale(0);

  width: 6px;
  height: 6px;

  background-color: var(--surface-brand-primary-default);
  border-radius: 50%;
}

.button {
  @extend %button;

  &::before {
    @extend %ellipse;

    visibility: hidden;
    opacity: 0;
  }
}

.active {
  @extend %button;

  @include active;
}

@keyframes growIn {
  to {
    transform: scale(1);
    opacity: 1;
  }
}

.highlight {
  &::before {
    @extend %ellipse;

    visibility: visible;
    opacity: 1;
    animation: growIn 250ms ease forwards;
  }
}

.icon {
  width: 24px;
  height: 24px;
}
