*,
*::before,
*::after {
  box-sizing: border-box;
  margin: 0;
  padding: 0;

  font-family: inherit;
  font-size: 100%;
  color: inherit;

  background: none;
}

button {
  cursor: pointer;
}
