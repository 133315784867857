@use "../styles/utils";

.container {
  @include utils.layout("regular") {
    position: absolute;
    gap: var(--spacing-4px);
    padding: 0 var(--spacing-16px);
  }

  @include utils.layout("compact") {
    position: relative;
    gap: var(--spacing-6px);
  }

  z-index: 1;
  bottom: 0;

  display: flex;
  flex-direction: column;
  align-items: center;
}
