@use "../styles/breakpoints.module.scss";

.modal {
  min-width: breakpoints.$xxs;
  max-width: 0.35 * breakpoints.$lg;
  max-height: 63%;

  background-color: var(--surface-neutral-primary-default-fill);
  border-radius: var(--radius-12px);
  box-shadow: var(--elevation-3), var(--elevation-3);

  @media (width > #{breakpoints.$md}) {
    min-width: 420px;
  }

  @media (width <= #{breakpoints.$sm}) {
    max-height: 90%;
  }
}

.contents {
  display: flex;
  flex-direction: column;
  max-height: 63dvh;

  & > *:not(header) {
    padding-right: var(--spacing-24px);
    padding-left: var(--spacing-24px);
  }

  @media (width <= #{breakpoints.$sm}) {
    max-height: 90dvh;
  }
}

