.container {
  position: relative;
}

.select {
  cursor: pointer;
  user-select: none;

  width: 100%;

  /*
   * Sufficient right padding necessary to avoid text becoming obfuscated by
   * the dropdown arrow
   */
  padding: 8px 35px 8px 16px;

  font: var(--text-sm-medium);
  text-overflow: ellipsis;

  appearance: none;
  background-color: var(--surface-neutral-primary-default-fill);
  border: var(--stroke-weight-1px) solid
    var(--surface-neutral-primary-default-stroke);
  border-radius: var(--radius-8px);

  transition: background-color 250ms;

  &:focus {
    padding: var(--spacing-8px) (var(--spacing-16px));
    border-color: var(--surface-neutral-primary-focus-stroke);
  }

  &:disabled {
    cursor: not-allowed;
    color: var(--text-on-neutral-primary-tertiary-disabled);
  }

  &:hover:not(:disabled) {
    background-color: var(--surface-neutral-primary-hover);
  }
}

.icon {
  // Otherwise prevents clicks from getting through to input
  pointer-events: none;

  position: absolute;
  top: 50%;
  right: var(--spacing-16px);
  transform: translateY(-50%);

  width: 20px;
  height: 20px;

  color: var(--icon-on-neutral-primary-secondary-default);
}
