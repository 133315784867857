/* ---------------------------------- *\
|             ⚠️ Warning! ⚠️             |
| This file is automatically generated |
|    Do not edit this file directly    |
\* ---------------------------------- */

@use "utils";

:root {
  /* ----------- *\
  | Raw Variables |
  \* ----------- */

  /* ----- *\
  | Colours |
  \* ----- */
  --colour-neutrals-neutral-25: #f8f9fc;
  --colour-neutrals-neutral-50: #eaecee;
  --colour-neutrals-neutral-100: #bfc5ca;
  --colour-neutrals-neutral-200: #9fa9b1;
  --colour-neutrals-neutral-300: #74828d;
  --colour-neutrals-neutral-400: #596977;
  --colour-neutrals-neutral-500: #2f4455;
  --colour-neutrals-neutral-600: #2b3e4d;
  --colour-neutrals-neutral-700: #21303c;
  --colour-neutrals-neutral-800: #1a252f;
  --colour-neutrals-neutral-900: #141d24;
  --colour-neutrals-neutral-black: #000;
  --colour-neutrals-neutral-white: #fff;
  --colour-primary-blue-blue-50: #eeefff;
  --colour-primary-blue-blue-100: #ccf;
  --colour-primary-blue-blue-200: #b3b4ff;
  --colour-primary-blue-blue-300: #9091ff;
  --colour-primary-blue-blue-400: #7a7cff;
  --colour-primary-blue-blue-500: #595bff;
  --colour-primary-blue-blue-600: #5153e8;
  --colour-primary-blue-blue-700: #3f41b5;
  --colour-primary-blue-blue-800: #31328c;
  --colour-primary-blue-blue-900: #25266b;
  --colour-primary-red-red-50: #fce9f1;
  --colour-primary-red-red-100: #f5bbd2;
  --colour-primary-red-red-200: #f19abc;
  --colour-primary-red-red-300: #ea6c9e;
  --colour-primary-red-red-400: #e6508b;
  --colour-primary-red-red-500: #e0246e;
  --colour-primary-red-red-600: #cc2164;
  --colour-primary-red-red-700: #9f1a4e;
  --colour-primary-red-red-800: #7b143d;
  --colour-primary-red-red-900: #5e0f2e;
  --colour-primary-teal-teal-50: #e7f3f3;
  --colour-primary-teal-teal-100: #b6dad9;
  --colour-primary-teal-teal-200: #92c8c7;
  --colour-primary-teal-teal-300: #61afae;
  --colour-primary-teal-teal-400: #42a09e;
  --colour-primary-teal-teal-500: #138886;
  --colour-primary-teal-teal-600: #117c7a;
  --colour-primary-teal-teal-700: #0d615f;
  --colour-primary-teal-teal-800: #0a4b4a;
  --colour-primary-teal-teal-900: #083938;
  --colour-primary-yellow-yellow-50: #fff8ef;
  --colour-primary-yellow-yellow-100: #ffe9cd;
  --colour-primary-yellow-yellow-200: #ffdeb4;
  --colour-primary-yellow-yellow-300: #ffcf92;
  --colour-primary-yellow-yellow-400: #ffc67d;
  --colour-primary-yellow-yellow-500: #ffb85d;
  --colour-primary-yellow-yellow-600: #e8a755;
  --colour-primary-yellow-yellow-700: #b58342;
  --colour-primary-yellow-yellow-800: #8c6533;
  --colour-primary-yellow-yellow-900: #6b4d27;
  --colour-secondary-green-green-50: #f4faef;
  --colour-secondary-green-green-100: #dbf0cd;
  --colour-secondary-green-green-200: #cae9b5;
  --colour-secondary-green-green-300: #b2df93;
  --colour-secondary-green-green-400: #a3d97e;
  --colour-secondary-green-green-500: #8ccf5e;
  --colour-secondary-green-green-600: #7fbc56;
  --colour-secondary-green-green-700: #639343;
  --colour-secondary-green-green-800: #4d7234;
  --colour-secondary-green-green-900: #3b5727;
  --colour-secondary-orange-orange-50: #fff3ef;
  --colour-secondary-orange-orange-100: #ffd9cd;
  --colour-secondary-orange-orange-200: #ffc6b4;
  --colour-secondary-orange-orange-300: #ffac92;
  --colour-secondary-orange-orange-400: #ff9c7d;
  --colour-secondary-orange-orange-500: #ff835d;
  --colour-secondary-orange-orange-600: #e87755;
  --colour-secondary-orange-orange-700: #b55d42;
  --colour-secondary-orange-orange-800: #8c4833;
  --colour-secondary-orange-orange-900: #6b3727;
  --colour-secondary-pink-pink-50: #fff5f9;
  --colour-secondary-pink-pink-100: #ffdeec;
  --colour-secondary-pink-pink-200: #ffcfe2;
  --colour-secondary-pink-pink-300: #ffb9d5;
  --colour-secondary-pink-pink-400: #ffabcd;
  --colour-secondary-pink-pink-500: #ff96c1;
  --colour-secondary-pink-pink-600: #e889b0;
  --colour-secondary-pink-pink-700: #b56b89;
  --colour-secondary-pink-pink-800: #8c536a;
  --colour-secondary-pink-pink-900: #6b3f51;
  --colour-secondary-purple-purple-50: #efedf7;
  --colour-secondary-purple-purple-100: #cdc6e7;
  --colour-secondary-purple-purple-200: #b5aadc;
  --colour-secondary-purple-purple-300: #9484cb;
  --colour-secondary-purple-purple-400: #7f6cc1;
  --colour-secondary-purple-purple-500: #5f47b2;
  --colour-secondary-purple-purple-600: #5641a2;
  --colour-secondary-purple-purple-700: #43327e;
  --colour-secondary-purple-purple-800: #342762;
  --colour-secondary-purple-purple-900: #281e4b;

  /* ----- *\
  | Numbers |
  \* ----- */
  --opacity-50: 50px;
  --opacity-100: 100px;
  --radius-0px: 0;
  --radius-4px: 4px;
  --radius-8px: 8px;
  --radius-12px: 12px;
  --radius-16px: 16px;
  --radius-24px: 24px;
  --radius-100: 1000px;
  --spacing-0px: 0;
  --spacing-4px: 4px;
  --spacing-8px: 8px;
  --spacing-12px: 12px;
  --spacing-16px: 16px;
  --spacing-24px: 24px;
  --spacing-32px: 32px;
  --spacing-40px: 40px;
  --spacing-48px: 48px;
  --spacing-56px: 56px;
  --spacing-64px: 64px;
  --stroke-weight-1px: 1px;
  --stroke-weight-2px: 2px;
  --stroke-weight-3px: 3px;
  --stroke-weight-4px: 4px;
  --stroke-weight-5px: 5px;

  /* ----- *\
  | Effects |
  \* ----- */
  --blur-1: 8px;
  --blur-2: 32px;
  --blur-3: 64px;
  --elevation-1: 0 1px 3px 1px rgb(0 0 0 / 4%), 0 1px 2px 0 rgb(0 0 0 / 8%);
  --elevation-2: 0 2px 6px 2px rgb(0 0 0 / 4%), 0 1px 2px 0 rgb(0 0 0 / 8%);
  --elevation-3: 0 1px 3px 0 rgb(0 0 0 / 8%), 0 4px 8px 3px rgb(0 0 0 / 4%);
  --elevation-4: 0 2px 3px 0 rgb(0 0 0 / 8%), 0 6px 10px 4px rgb(0 0 0 / 4%);
  --elevation-5: 0 4px 4px 0 rgb(0 0 0 / 8%), 0 8px 12px 6px rgb(0 0 0 / 4%);
  --temp: 0 11px 22px 0 rgb(0 0 0 / 28%);

  /* -------- *\
  | Typography |
  \* -------- */
  --display-2xl-bold: 700 calc(72 * 0.0625rem) / 1.5 "Plus Jakarta Sans";
  --display-2xl-medium: 500 calc(72 * 0.0625rem) / 1.5 "Plus Jakarta Sans";
  --display-2xl-regular: 400 calc(72 * 0.0625rem) / 1.5 "Plus Jakarta Sans";
  --display-2xl-semibold: 600 calc(72 * 0.0625rem) / 1.5 "Plus Jakarta Sans";
  --display-lg-bold: 700 calc(48 * 0.0625rem) / 1.5 "Plus Jakarta Sans";
  --display-lg-medium: 500 calc(48 * 0.0625rem) / 1.5 "Plus Jakarta Sans";
  --display-lg-regular: 400 calc(48 * 0.0625rem) / 1.5 "Plus Jakarta Sans";
  --display-lg-semibold: 600 calc(48 * 0.0625rem) / 1.5 "Plus Jakarta Sans";
  --display-md-bold: 700 calc(36 * 0.0625rem) / 1.5 "Plus Jakarta Sans";
  --display-md-medium: 500 calc(36 * 0.0625rem) / 1.5 "Plus Jakarta Sans";
  --display-md-regular: 400 calc(36 * 0.0625rem) / 1.5 "Plus Jakarta Sans";
  --display-md-semibold: 600 calc(36 * 0.0625rem) / 1.5 "Plus Jakarta Sans";
  --display-sm-bold: 700 calc(30 * 0.0625rem) / 1.5 "Plus Jakarta Sans";
  --display-sm-medium: 500 calc(30 * 0.0625rem) / 1.5 "Plus Jakarta Sans";
  --display-sm-regular: 400 calc(30 * 0.0625rem) / 1.5 "Plus Jakarta Sans";
  --display-sm-semibold: 600 calc(30 * 0.0625rem) / 1.5 "Plus Jakarta Sans";
  --display-xl-bold: 700 calc(60 * 0.0625rem) / 1.5 "Plus Jakarta Sans";
  --display-xl-medium: 500 calc(60 * 0.0625rem) / 1.5 "Plus Jakarta Sans";
  --display-xl-regular: 400 calc(60 * 0.0625rem) / 1.5 "Plus Jakarta Sans";
  --display-xl-semibold: 600 calc(60 * 0.0625rem) / 1.5 "Plus Jakarta Sans";
  --display-xs-bold: 700 calc(24 * 0.0625rem) / 1.5 "Plus Jakarta Sans";
  --display-xs-medium: 500 calc(24 * 0.0625rem) / 1.5 "Plus Jakarta Sans";
  --display-xs-regular: 400 calc(24 * 0.0625rem) / 1.5 "Plus Jakarta Sans";
  --display-xs-semibold: 600 calc(24 * 0.0625rem) / 1.5 "Plus Jakarta Sans";
  --text-lg-bold: 700 calc(18 * 0.0625rem) / 1.61 "Plus Jakarta Sans";
  --text-lg-bold-underlined: 700 calc(18 * 0.0625rem) / 1.61 "Plus Jakarta Sans";
  --text-lg-medium: 500 calc(18 * 0.0625rem) / 1.61 "Plus Jakarta Sans";
  --text-lg-medium-underlined: 500 calc(18 * 0.0625rem) / 1.61
    "Plus Jakarta Sans";
  --text-lg-regular: 400 calc(18 * 0.0625rem) / 1.61 "Plus Jakarta Sans";
  --text-lg-regular-underlined: 400 calc(18 * 0.0625rem) / 1.61
    "Plus Jakarta Sans";
  --text-lg-semibold: 600 calc(18 * 0.0625rem) / 1.61 "Plus Jakarta Sans";
  --text-lg-semibold-underlined: 600 calc(18 * 0.0625rem) / 1.61
    "Plus Jakarta Sans";
  --text-md-bold: 700 calc(16 * 0.0625rem) / 1.61 "Plus Jakarta Sans";
  --text-md-bold-underlined: 700 calc(16 * 0.0625rem) / 1.61 "Plus Jakarta Sans";
  --text-md-medium: 500 calc(16 * 0.0625rem) / 1.61 "Plus Jakarta Sans";
  --text-md-medium-underlined: 500 calc(16 * 0.0625rem) / 1.61
    "Plus Jakarta Sans";
  --text-md-regular: 400 calc(16 * 0.0625rem) / 1.61 "Plus Jakarta Sans";
  --text-md-regular-underlined: 400 calc(16 * 0.0625rem) / 1.61
    "Plus Jakarta Sans";
  --text-md-semibold: 600 calc(16 * 0.0625rem) / 1.61 "Plus Jakarta Sans";
  --text-md-semibold-underlined: 600 calc(16 * 0.0625rem) / 1.61
    "Plus Jakarta Sans";
  --text-sm-bold: 700 calc(14 * 0.0625rem) / 1.61 "Plus Jakarta Sans";
  --text-sm-bold-underlined: 700 calc(14 * 0.0625rem) / 1.61 "Plus Jakarta Sans";
  --text-sm-medium: 500 calc(14 * 0.0625rem) / 1.61 "Plus Jakarta Sans";
  --text-sm-medium-underlined: 500 calc(14 * 0.0625rem) / 1.61
    "Plus Jakarta Sans";
  --text-sm-regular: 400 calc(14 * 0.0625rem) / 1.61 "Plus Jakarta Sans";
  --text-sm-regular-underlined: 400 calc(14 * 0.0625rem) / 1.61
    "Plus Jakarta Sans";
  --text-sm-semibold: 600 calc(14 * 0.0625rem) / 1.61 "Plus Jakarta Sans";
  --text-sm-semibold-underlined: 600 calc(14 * 0.0625rem) / 1.61
    "Plus Jakarta Sans";
  --text-xl-bold: 700 calc(20 * 0.0625rem) / 1.61 "Plus Jakarta Sans";
  --text-xl-bold-underlined: 700 calc(20 * 0.0625rem) / 1.61 "Plus Jakarta Sans";
  --text-xl-medium: 500 calc(20 * 0.0625rem) / 1.61 "Plus Jakarta Sans";
  --text-xl-medium-underlined: 500 calc(20 * 0.0625rem) / 1.61
    "Plus Jakarta Sans";
  --text-xl-regular: 400 calc(20 * 0.0625rem) / 1.61 "Plus Jakarta Sans";
  --text-xl-regular-underlined: 400 calc(20 * 0.0625rem) / 1.61
    "Plus Jakarta Sans";
  --text-xl-semibold: 600 calc(20 * 0.0625rem) / 1.61 "Plus Jakarta Sans";
  --text-xl-semibold-underlined: 600 calc(20 * 0.0625rem) / 1.61
    "Plus Jakarta Sans";
  --text-xs-bold: 700 calc(12 * 0.0625rem) / 1.61 "Plus Jakarta Sans";
  --text-xs-bold-underlined: 700 calc(12 * 0.0625rem) / 1.61 "Plus Jakarta Sans";
  --text-xs-medium: 500 calc(12 * 0.0625rem) / 1.61 "Plus Jakarta Sans";
  --text-xs-medium-underlined: 500 calc(12 * 0.0625rem) / 1.61
    "Plus Jakarta Sans";
  --text-xs-regular: 400 calc(12 * 0.0625rem) / 1.61 "Plus Jakarta Sans";
  --text-xs-regular-underlined: 400 calc(12 * 0.0625rem) / 1.61
    "Plus Jakarta Sans";
  --text-xs-semibold: 600 calc(12 * 0.0625rem) / 1.61 "Plus Jakarta Sans";
  --text-xs-semibold-underlined: 600 calc(12 * 0.0625rem) / 1.61
    "Plus Jakarta Sans";

  /* -------------- *\
  | Themed Variables |
  \* -------------- */
  @include utils.theme(light) {
    /* ------------------- *\
    | Light Theme Variables |
    \* ------------------- */

    /* ----- *\
    | Colours |
    \* ----- */
    --icon-on-alert-primary-primary-default: var(--colour-neutrals-neutral-600);
    --icon-on-alert-primary-primary-disabled: var(
      --colour-neutrals-neutral-100
    );
    --icon-on-alert-primary-primary-hover: var(--colour-neutrals-neutral-700);
    --icon-on-alert-secondary-primary-default: var(
      --colour-primary-yellow-yellow-600
    );
    --icon-on-alert-secondary-primary-disabled: var(
      --colour-neutrals-neutral-100
    );
    --icon-on-alert-secondary-primary-hover: var(
      --colour-primary-yellow-yellow-700
    );
    --icon-on-brand-primary-primary-default: var(
      --colour-neutrals-neutral-white
    );
    --icon-on-brand-primary-primary-disabled: var(
      --colour-neutrals-neutral-100
    );
    --icon-on-brand-primary-primary-hover: var(--colour-primary-blue-blue-50);
    --icon-on-brand-secondary-primary-default: var(
      --colour-primary-blue-blue-500
    );
    --icon-on-brand-secondary-primary-disabled: var(
      --colour-neutrals-neutral-100
    );
    --icon-on-brand-secondary-primary-hover: var(
      --colour-primary-blue-blue-600
    );
    --icon-on-destructive-primary-primary-default: var(
      --colour-neutrals-neutral-white
    );
    --icon-on-destructive-primary-primary-disabled: var(
      --colour-neutrals-neutral-100
    );
    --icon-on-destructive-primary-primary-hover: var(
      --colour-primary-red-red-50
    );
    --icon-on-destructive-secondary-primary-default: var(
      --colour-primary-red-red-600
    );
    --icon-on-destructive-secondary-primary-disabled: var(
      --colour-neutrals-neutral-100
    );
    --icon-on-destructive-secondary-primary-hover: var(
      --colour-primary-red-red-700
    );
    --icon-on-neutral-primary-primary-default: var(
      --colour-neutrals-neutral-600
    );
    --icon-on-neutral-primary-primary-disabled: var(
      --colour-neutrals-neutral-100
    );
    --icon-on-neutral-primary-primary-hover: var(--colour-neutrals-neutral-700);
    --icon-on-neutral-primary-secondary-default: var(
      --colour-neutrals-neutral-300
    );
    --icon-on-neutral-primary-secondary-disabled: var(
      --colour-neutrals-neutral-100
    );
    --icon-on-neutral-primary-secondary-hover: var(
      --colour-neutrals-neutral-400
    );
    --icon-on-neutral-secondary-primary-default: var(
      --colour-neutrals-neutral-600
    );
    --icon-on-neutral-secondary-primary-disabled: var(
      --colour-neutrals-neutral-100
    );
    --icon-on-neutral-secondary-primary-hover: var(
      --colour-neutrals-neutral-700
    );
    --icon-on-neutral-secondary-secondary-default: var(
      --colour-neutrals-neutral-300
    );
    --icon-on-neutral-secondary-secondary-disabled: var(
      --colour-neutrals-neutral-100
    );
    --icon-on-neutral-secondary-secondary-hover: var(
      --colour-neutrals-neutral-400
    );
    --icon-on-positive-primary-primary-default: var(
      --colour-neutrals-neutral-white
    );
    --icon-on-positive-primary-primary-disabled: var(
      --colour-neutrals-neutral-100
    );
    --icon-on-positive-primary-primary-hover: var(
      --colour-primary-teal-teal-50
    );
    --icon-on-positive-secondary-primary-default: var(
      --colour-primary-teal-teal-700
    );
    --icon-on-positive-secondary-primary-disabled: var(
      --colour-neutrals-neutral-100
    );
    --icon-on-positive-secondary-primary-hover: var(
      --colour-primary-teal-teal-800
    );
    --surface-alert-primary-default: var(--colour-primary-yellow-yellow-500);
    --surface-alert-primary-default-stroke: var(
      --colour-primary-yellow-yellow-600
    );
    --surface-alert-primary-disabled: var(--colour-neutrals-neutral-25);
    --surface-alert-primary-focus-stroke: var(
      --colour-primary-yellow-yellow-500
    );
    --surface-alert-primary-hover: var(--colour-primary-yellow-yellow-600);
    --surface-alert-secondary-default: var(--colour-primary-yellow-yellow-50);
    --surface-alert-secondary-default-stroke: var(
      --colour-primary-yellow-yellow-100
    );
    --surface-alert-secondary-disabled: var(--colour-neutrals-neutral-25);
    --surface-alert-secondary-focus-stroke: var(
      --colour-primary-yellow-yellow-500
    );
    --surface-alert-secondary-hover: var(--colour-primary-yellow-yellow-100);
    --surface-brand-primary-default: var(--colour-primary-blue-blue-500);
    --surface-brand-primary-default-stroke: var(--colour-primary-blue-blue-600);
    --surface-brand-primary-disabled: var(--colour-neutrals-neutral-25);
    --surface-brand-primary-focus-stroke: var(
      --colour-primary-yellow-yellow-500
    );
    --surface-brand-primary-hover: var(--colour-primary-blue-blue-600);
    --surface-brand-secondary-default: var(--colour-primary-blue-blue-50);
    --surface-brand-secondary-default-stroke: var(
      --colour-primary-blue-blue-400
    );
    --surface-brand-secondary-disabled: var(--colour-neutrals-neutral-25);
    --surface-brand-secondary-focus-stroke: var(
      --colour-primary-yellow-yellow-500
    );
    --surface-brand-secondary-hover: var(--colour-primary-blue-blue-100);
    --surface-destructive-primary-default-fill: var(
      --colour-primary-red-red-600
    );
    --surface-destructive-primary-default-stroke: var(
      --colour-primary-red-red-700
    );
    --surface-destructive-primary-disabled: var(--colour-neutrals-neutral-25);
    --surface-destructive-primary-focus-stroke: var(
      --colour-primary-yellow-yellow-500
    );
    --surface-destructive-primary-hover: var(--colour-primary-red-red-700);
    --surface-destructive-secondary-default-fill: var(
      --colour-primary-red-red-50
    );
    --surface-destructive-secondary-default-stroke: var(
      --colour-primary-red-red-100
    );
    --surface-destructive-secondary-disabled: var(--colour-neutrals-neutral-25);
    --surface-destructive-secondary-focus-stroke: var(
      --colour-primary-yellow-yellow-500
    );
    --surface-destructive-secondary-hover: var(--colour-primary-red-red-100);
    --surface-invert-primary-default-fill: var(--colour-neutrals-neutral-900);
    --surface-neutral-primary-default-fill: var(
      --colour-neutrals-neutral-white
    );
    --surface-neutral-primary-default-stroke: var(--colour-neutrals-neutral-50);
    --surface-neutral-primary-disabled: var(--colour-neutrals-neutral-white);
    --surface-neutral-primary-focus-stroke: var(
      --colour-primary-yellow-yellow-500
    );
    --surface-neutral-primary-hover: var(--colour-neutrals-neutral-25);
    --surface-neutral-secondary-default-fill: var(--colour-neutrals-neutral-25);
    --surface-neutral-secondary-default-stroke: var(
      --colour-neutrals-neutral-50
    );
    --surface-neutral-secondary-disabled: var(--colour-neutrals-neutral-white);
    --surface-neutral-secondary-focus-stroke: var(
      --colour-primary-yellow-yellow-500
    );
    --surface-neutral-secondary-hover: var(--colour-neutrals-neutral-50);
    --surface-palette-green-primary-default-fill: var(
      --colour-secondary-green-green-500
    );
    --surface-palette-green-primary-default-stroke: var(
      --colour-secondary-green-green-600
    );
    --surface-palette-green-primary-focus-stroke: var(
      --colour-primary-yellow-yellow-500
    );
    --surface-palette-green-primary-hover: var(
      --colour-secondary-green-green-600
    );
    --surface-palette-green-secondary-default-fill: var(
      --colour-secondary-green-green-50
    );
    --surface-palette-green-secondary-default-stroke: var(
      --colour-secondary-green-green-100
    );
    --surface-palette-green-secondary-focus-stroke: var(
      --colour-primary-yellow-yellow-500
    );
    --surface-palette-green-secondary-hover: var(
      --colour-secondary-green-green-100
    );
    --surface-palette-orange-primary-default-fill: var(
      --colour-secondary-orange-orange-500
    );
    --surface-palette-orange-primary-default-stroke: var(
      --colour-secondary-orange-orange-600
    );
    --surface-palette-orange-primary-focus-stroke: var(
      --colour-primary-yellow-yellow-500
    );
    --surface-palette-orange-primary-hover: var(
      --colour-secondary-orange-orange-600
    );
    --surface-palette-orange-secondary-default-fill: var(
      --colour-secondary-orange-orange-50
    );
    --surface-palette-orange-secondary-default-stroke: var(
      --colour-secondary-orange-orange-100
    );
    --surface-palette-orange-secondary-focus-stroke: var(
      --colour-primary-yellow-yellow-500
    );
    --surface-palette-orange-secondary-hover: var(
      --colour-secondary-orange-orange-100
    );
    --surface-palette-pink-primary-default-fill: var(
      --colour-secondary-pink-pink-500
    );
    --surface-palette-pink-primary-default-stroke: var(
      --colour-secondary-pink-pink-600
    );
    --surface-palette-pink-primary-focus-stroke: var(
      --colour-primary-yellow-yellow-500
    );
    --surface-palette-pink-primary-hover: var(--colour-secondary-pink-pink-600);
    --surface-palette-pink-secondary-default-fill: var(
      --colour-secondary-pink-pink-50
    );
    --surface-palette-pink-secondary-default-stroke: var(
      --colour-secondary-pink-pink-100
    );
    --surface-palette-pink-secondary-focus-stroke: var(
      --colour-primary-yellow-yellow-500
    );
    --surface-palette-pink-secondary-hover: var(
      --colour-secondary-pink-pink-100
    );
    --surface-palette-purple-primary-default-fill: var(
      --colour-secondary-purple-purple-500
    );
    --surface-palette-purple-primary-default-stroke: var(
      --colour-secondary-purple-purple-600
    );
    --surface-palette-purple-primary-focus-stroke: var(
      --colour-primary-yellow-yellow-500
    );
    --surface-palette-purple-primary-hover: var(
      --colour-secondary-purple-purple-600
    );
    --surface-palette-purple-secondary-default-fill: var(
      --colour-secondary-purple-purple-50
    );
    --surface-palette-purple-secondary-default-stroke: var(
      --colour-secondary-purple-purple-100
    );
    --surface-palette-purple-secondary-focus-stroke: var(
      --colour-primary-yellow-yellow-500
    );
    --surface-palette-purple-secondary-hover: var(
      --colour-secondary-purple-purple-100
    );
    --surface-positive-primary-default-fill: var(
      --colour-primary-teal-teal-600
    );
    --surface-positive-primary-default-stroke: var(
      --colour-primary-teal-teal-700
    );
    --surface-positive-primary-disabled: var(--colour-neutrals-neutral-25);
    --surface-positive-primary-focus-stroke: var(
      --colour-primary-yellow-yellow-500
    );
    --surface-positive-primary-hover: var(--colour-primary-teal-teal-700);
    --surface-positive-secondary-default: var(--colour-primary-teal-teal-50);
    --surface-positive-secondary-default-stroke: var(
      --colour-primary-teal-teal-100
    );
    --surface-positive-secondary-disabled: var(--colour-neutrals-neutral-25);
    --surface-positive-secondary-focus-stroke: var(
      --colour-primary-yellow-yellow-500
    );
    --surface-positive-secondary-hover: var(--colour-primary-teal-teal-100);
    --text-on-alert-primary-primary-default: var(--colour-neutrals-neutral-800);
    --text-on-alert-primary-primary-disabled: var(
      --colour-neutrals-neutral-100
    );
    --text-on-alert-primary-primary-hover: var(--colour-neutrals-neutral-900);
    --text-on-alert-secondary-primary-default: var(
      --colour-primary-yellow-yellow-800
    );
    --text-on-alert-secondary-primary-disabled: var(
      --colour-neutrals-neutral-100
    );
    --text-on-alert-secondary-primary-hover: var(
      --colour-primary-yellow-yellow-900
    );
    --text-on-brand-primary-primary-default: var(
      --colour-neutrals-neutral-white
    );
    --text-on-brand-primary-primary-disabled: var(
      --colour-neutrals-neutral-100
    );
    --text-on-brand-primary-primary-hover: var(--colour-primary-blue-blue-50);
    --text-on-brand-secondary-primary-default: var(
      --colour-primary-blue-blue-600
    );
    --text-on-brand-secondary-primary-disabled: var(
      --colour-neutrals-neutral-100
    );
    --text-on-brand-secondary-primary-hover: var(
      --colour-primary-blue-blue-700
    );
    --text-on-destructive-primary-primary-default: var(
      --colour-neutrals-neutral-white
    );
    --text-on-destructive-primary-primary-disabled: var(
      --colour-neutrals-neutral-100
    );
    --text-on-destructive-primary-primary-hover: var(
      --colour-primary-red-red-50
    );
    --text-on-destructive-secondary-primary-default: var(
      --colour-primary-red-red-600
    );
    --text-on-destructive-secondary-primary-disabled: var(
      --colour-neutrals-neutral-100
    );
    --text-on-destructive-secondary-primary-hover: var(
      --colour-primary-red-red-700
    );
    --text-on-invert-primary-primary-default: var(
      --colour-neutrals-neutral-white
    );
    --text-on-neutral-primary-primary-default: var(
      --colour-neutrals-neutral-800
    );
    --text-on-neutral-primary-primary-disabled: var(
      --colour-neutrals-neutral-100
    );
    --text-on-neutral-primary-primary-hover: var(--colour-neutrals-neutral-900);
    --text-on-neutral-primary-secondary-default: var(
      --colour-neutrals-neutral-400
    );
    --text-on-neutral-primary-secondary-disabled: var(
      --colour-neutrals-neutral-100
    );
    --text-on-neutral-primary-secondary-hover: var(
      --colour-neutrals-neutral-500
    );
    --text-on-neutral-primary-tertiary-default: var(
      --colour-neutrals-neutral-300
    );
    --text-on-neutral-primary-tertiary-disabled: var(
      --colour-neutrals-neutral-100
    );
    --text-on-neutral-primary-tertiary-hover: var(
      --colour-neutrals-neutral-200
    );
    --text-on-neutral-secondary-primary-default: var(
      --colour-neutrals-neutral-800
    );
    --text-on-neutral-secondary-primary-disabled: var(
      --colour-neutrals-neutral-100
    );
    --text-on-neutral-secondary-primary-hover: var(
      --colour-neutrals-neutral-800
    );
    --text-on-neutral-secondary-secondary-default: var(
      --colour-neutrals-neutral-400
    );
    --text-on-neutral-secondary-secondary-disabled: var(
      --colour-neutrals-neutral-100
    );
    --text-on-neutral-secondary-secondary-hover: var(
      --colour-neutrals-neutral-400
    );
    --text-on-neutral-secondary-tertiary-default: var(
      --colour-neutrals-neutral-200
    );
    --text-on-neutral-secondary-tertiary-disabled: var(
      --colour-neutrals-neutral-100
    );
    --text-on-neutral-secondary-tertiary-hover: var(
      --colour-neutrals-neutral-200
    );
    --text-on-positive-primary-primary-default: var(
      --colour-neutrals-neutral-white
    );
    --text-on-positive-primary-primary-disabled: var(
      --colour-neutrals-neutral-100
    );
    --text-on-positive-primary-primary-hover: var(
      --colour-primary-teal-teal-50
    );
    --text-on-positive-secondary-primary-default: var(
      --colour-primary-teal-teal-700
    );
    --text-on-positive-secondary-primary-disabled: var(
      --colour-neutrals-neutral-100
    );
    --text-on-positive-secondary-primary-hover: var(
      --colour-primary-teal-teal-800
    );
    --text-palette-on-green-primary-primary-default: var(
      --colour-neutrals-neutral-white
    );
    --text-palette-on-green-secondary-primary-default: var(
      --colour-secondary-green-green-700
    );
    --text-palette-on-orange-primary-primary-default: var(
      --colour-neutrals-neutral-white
    );
    --text-palette-on-orange-secondary-primary-default: var(
      --colour-secondary-orange-orange-600
    );
    --text-palette-on-pink-primary-primary-default: var(
      --colour-neutrals-neutral-white
    );
    --text-palette-on-pink-secondary-primary-default: var(
      --colour-secondary-pink-pink-800
    );
    --text-palette-on-purple-primary-primary-default: var(
      --colour-neutrals-neutral-white
    );
    --text-palette-on-purple-secondary-primary-default: var(
      --colour-secondary-purple-purple-600
    );
  }

  @include utils.theme(dark) {
    /* ------------------ *\
    | Dark Theme Variables |
    \* ------------------ */

    /* ----- *\
    | Colours |
    \* ----- */
    --icon-on-alert-primary-primary-default: var(--colour-neutrals-neutral-600);
    --icon-on-alert-primary-primary-disabled: var(
      --colour-neutrals-neutral-500
    );
    --icon-on-alert-primary-primary-hover: var(--colour-neutrals-neutral-700);
    --icon-on-alert-secondary-primary-default: var(
      --colour-primary-yellow-yellow-200
    );
    --icon-on-alert-secondary-primary-disabled: var(
      --colour-neutrals-neutral-500
    );
    --icon-on-alert-secondary-primary-hover: var(
      --colour-primary-yellow-yellow-100
    );
    --icon-on-brand-primary-primary-default: var(
      --colour-neutrals-neutral-white
    );
    --icon-on-brand-primary-primary-disabled: var(
      --colour-neutrals-neutral-500
    );
    --icon-on-brand-primary-primary-hover: var(--colour-neutrals-neutral-white);
    --icon-on-brand-secondary-primary-default: var(
      --colour-primary-blue-blue-100
    );
    --icon-on-brand-secondary-primary-disabled: var(
      --colour-neutrals-neutral-500
    );
    --icon-on-brand-secondary-primary-hover: var(--colour-primary-blue-blue-50);
    --icon-on-destructive-primary-primary-default: var(
      --colour-neutrals-neutral-white
    );
    --icon-on-destructive-primary-primary-disabled: var(
      --colour-neutrals-neutral-500
    );
    --icon-on-destructive-primary-primary-hover: var(
      --colour-neutrals-neutral-white
    );
    --icon-on-destructive-secondary-primary-default: var(
      --colour-primary-red-red-100
    );
    --icon-on-destructive-secondary-primary-disabled: var(
      --colour-neutrals-neutral-500
    );
    --icon-on-destructive-secondary-primary-hover: var(
      --colour-primary-red-red-50
    );
    --icon-on-neutral-primary-primary-default: var(
      --colour-neutrals-neutral-50
    );
    --icon-on-neutral-primary-primary-disabled: var(
      --colour-neutrals-neutral-500
    );
    --icon-on-neutral-primary-primary-hover: var(
      --colour-neutrals-neutral-white
    );
    --icon-on-neutral-primary-secondary-default: var(
      --colour-neutrals-neutral-200
    );
    --icon-on-neutral-primary-secondary-disabled: var(
      --colour-neutrals-neutral-500
    );
    --icon-on-neutral-primary-secondary-hover: var(
      --colour-neutrals-neutral-100
    );
    --icon-on-neutral-secondary-primary-default: var(
      --colour-neutrals-neutral-50
    );
    --icon-on-neutral-secondary-primary-disabled: var(
      --colour-neutrals-neutral-500
    );
    --icon-on-neutral-secondary-primary-hover: var(
      --colour-neutrals-neutral-white
    );
    --icon-on-neutral-secondary-secondary-default: var(
      --colour-neutrals-neutral-200
    );
    --icon-on-neutral-secondary-secondary-disabled: var(
      --colour-neutrals-neutral-500
    );
    --icon-on-neutral-secondary-secondary-hover: var(
      --colour-neutrals-neutral-100
    );
    --icon-on-positive-primary-primary-default: var(
      --colour-neutrals-neutral-white
    );
    --icon-on-positive-primary-primary-disabled: var(
      --colour-neutrals-neutral-500
    );
    --icon-on-positive-primary-primary-hover: var(
      --colour-neutrals-neutral-white
    );
    --icon-on-positive-secondary-primary-default: var(
      --colour-primary-teal-teal-100
    );
    --icon-on-positive-secondary-primary-disabled: var(
      --colour-neutrals-neutral-500
    );
    --icon-on-positive-secondary-primary-hover: var(
      --colour-primary-teal-teal-50
    );
    --surface-alert-primary-default: var(--colour-primary-yellow-yellow-500);
    --surface-alert-primary-default-stroke: var(
      --colour-primary-yellow-yellow-400
    );
    --surface-alert-primary-disabled: var(--colour-neutrals-neutral-700);
    --surface-alert-primary-focus-stroke: var(
      --colour-primary-yellow-yellow-400
    );
    --surface-alert-primary-hover: var(--colour-primary-yellow-yellow-400);
    --surface-alert-secondary-default: var(--colour-primary-yellow-yellow-800);
    --surface-alert-secondary-default-stroke: var(
      --colour-primary-yellow-yellow-700
    );
    --surface-alert-secondary-disabled: var(--colour-neutrals-neutral-700);
    --surface-alert-secondary-focus-stroke: var(
      --colour-primary-yellow-yellow-400
    );
    --surface-alert-secondary-hover: var(--colour-primary-yellow-yellow-700);
    --surface-brand-primary-default: var(--colour-primary-blue-blue-500);
    --surface-brand-primary-default-stroke: var(--colour-primary-blue-blue-400);
    --surface-brand-primary-disabled: var(--colour-neutrals-neutral-700);
    --surface-brand-primary-focus-stroke: var(
      --colour-primary-yellow-yellow-400
    );
    --surface-brand-primary-hover: var(--colour-primary-blue-blue-400);
    --surface-brand-secondary-default: var(--colour-primary-blue-blue-800);
    --surface-brand-secondary-default-stroke: var(
      --colour-primary-blue-blue-500
    );
    --surface-brand-secondary-disabled: var(--colour-neutrals-neutral-700);
    --surface-brand-secondary-focus-stroke: var(
      --colour-primary-yellow-yellow-400
    );
    --surface-brand-secondary-hover: var(--colour-primary-blue-blue-700);
    --surface-destructive-primary-default-fill: var(
      --colour-primary-red-red-600
    );
    --surface-destructive-primary-default-stroke: var(
      --colour-primary-red-red-500
    );
    --surface-destructive-primary-disabled: var(--colour-neutrals-neutral-700);
    --surface-destructive-primary-focus-stroke: var(
      --colour-primary-yellow-yellow-400
    );
    --surface-destructive-primary-hover: var(--colour-primary-red-red-500);
    --surface-destructive-secondary-default-fill: var(
      --colour-primary-red-red-800
    );
    --surface-destructive-secondary-default-stroke: var(
      --colour-primary-red-red-700
    );
    --surface-destructive-secondary-disabled: var(
      --colour-neutrals-neutral-700
    );
    --surface-destructive-secondary-focus-stroke: var(
      --colour-primary-yellow-yellow-400
    );
    --surface-destructive-secondary-hover: var(--colour-primary-red-red-700);
    --surface-invert-primary-default-fill: var(--colour-neutrals-neutral-900);
    --surface-neutral-primary-default-fill: var(--colour-neutrals-neutral-800);
    --surface-neutral-primary-default-stroke: var(
      --colour-neutrals-neutral-600
    );
    --surface-neutral-primary-disabled: var(--colour-neutrals-neutral-800);
    --surface-neutral-primary-focus-stroke: var(
      --colour-primary-yellow-yellow-400
    );
    --surface-neutral-primary-hover: var(--colour-neutrals-neutral-700);
    --surface-neutral-secondary-default-fill: var(
      --colour-neutrals-neutral-700
    );
    --surface-neutral-secondary-default-stroke: var(
      --colour-neutrals-neutral-500
    );
    --surface-neutral-secondary-disabled: var(--colour-neutrals-neutral-700);
    --surface-neutral-secondary-focus-stroke: var(
      --colour-primary-yellow-yellow-400
    );
    --surface-neutral-secondary-hover: var(--colour-neutrals-neutral-600);
    --surface-palette-green-primary-default-fill: var(
      --colour-secondary-green-green-500
    );
    --surface-palette-green-primary-default-stroke: var(
      --colour-secondary-green-green-400
    );
    --surface-palette-green-primary-focus-stroke: var(
      --colour-primary-yellow-yellow-400
    );
    --surface-palette-green-primary-hover: var(
      --colour-secondary-green-green-400
    );
    --surface-palette-green-secondary-default-fill: var(
      --colour-secondary-green-green-800
    );
    --surface-palette-green-secondary-default-stroke: var(
      --colour-secondary-green-green-700
    );
    --surface-palette-green-secondary-focus-stroke: var(
      --colour-primary-yellow-yellow-400
    );
    --surface-palette-green-secondary-hover: var(
      --colour-secondary-green-green-700
    );
    --surface-palette-orange-primary-default-fill: var(
      --colour-secondary-orange-orange-500
    );
    --surface-palette-orange-primary-default-stroke: var(
      --colour-secondary-orange-orange-400
    );
    --surface-palette-orange-primary-focus-stroke: var(
      --colour-primary-yellow-yellow-400
    );
    --surface-palette-orange-primary-hover: var(
      --colour-secondary-orange-orange-600
    );
    --surface-palette-orange-secondary-default-fill: var(
      --colour-secondary-orange-orange-800
    );
    --surface-palette-orange-secondary-default-stroke: var(
      --colour-secondary-orange-orange-700
    );
    --surface-palette-orange-secondary-focus-stroke: var(
      --colour-primary-yellow-yellow-400
    );
    --surface-palette-orange-secondary-hover: var(
      --colour-secondary-orange-orange-700
    );
    --surface-palette-pink-primary-default-fill: var(
      --colour-secondary-pink-pink-500
    );
    --surface-palette-pink-primary-default-stroke: var(
      --colour-secondary-pink-pink-400
    );
    --surface-palette-pink-primary-focus-stroke: var(
      --colour-primary-yellow-yellow-400
    );
    --surface-palette-pink-primary-hover: var(--colour-secondary-pink-pink-400);
    --surface-palette-pink-secondary-default-fill: var(
      --colour-secondary-pink-pink-800
    );
    --surface-palette-pink-secondary-default-stroke: var(
      --colour-secondary-pink-pink-700
    );
    --surface-palette-pink-secondary-focus-stroke: var(
      --colour-primary-yellow-yellow-400
    );
    --surface-palette-pink-secondary-hover: var(
      --colour-secondary-pink-pink-700
    );
    --surface-palette-purple-primary-default-fill: var(
      --colour-secondary-purple-purple-500
    );
    --surface-palette-purple-primary-default-stroke: var(
      --colour-secondary-purple-purple-400
    );
    --surface-palette-purple-primary-focus-stroke: var(
      --colour-primary-yellow-yellow-400
    );
    --surface-palette-purple-primary-hover: var(
      --colour-secondary-purple-purple-400
    );
    --surface-palette-purple-secondary-default-fill: var(
      --colour-secondary-purple-purple-700
    );
    --surface-palette-purple-secondary-default-stroke: var(
      --colour-secondary-purple-purple-600
    );
    --surface-palette-purple-secondary-focus-stroke: var(
      --colour-primary-yellow-yellow-400
    );
    --surface-palette-purple-secondary-hover: var(
      --colour-secondary-purple-purple-600
    );
    --surface-positive-primary-default-fill: var(
      --colour-primary-teal-teal-500
    );
    --surface-positive-primary-default-stroke: var(
      --colour-primary-teal-teal-400
    );
    --surface-positive-primary-disabled: var(--colour-neutrals-neutral-700);
    --surface-positive-primary-focus-stroke: var(
      --colour-primary-yellow-yellow-400
    );
    --surface-positive-primary-hover: var(--colour-primary-teal-teal-400);
    --surface-positive-secondary-default: var(--colour-primary-teal-teal-800);
    --surface-positive-secondary-default-stroke: var(
      --colour-primary-teal-teal-700
    );
    --surface-positive-secondary-disabled: var(--colour-neutrals-neutral-700);
    --surface-positive-secondary-focus-stroke: var(
      --colour-primary-yellow-yellow-400
    );
    --surface-positive-secondary-hover: var(--colour-primary-teal-teal-700);
    --text-on-alert-primary-primary-default: var(--colour-neutrals-neutral-800);
    --text-on-alert-primary-primary-disabled: var(
      --colour-neutrals-neutral-500
    );
    --text-on-alert-primary-primary-hover: var(--colour-neutrals-neutral-900);
    --text-on-alert-secondary-primary-default: var(
      --colour-primary-yellow-yellow-200
    );
    --text-on-alert-secondary-primary-disabled: var(
      --colour-neutrals-neutral-500
    );
    --text-on-alert-secondary-primary-hover: var(
      --colour-primary-yellow-yellow-50
    );
    --text-on-brand-primary-primary-default: var(
      --colour-neutrals-neutral-white
    );
    --text-on-brand-primary-primary-disabled: var(
      --colour-neutrals-neutral-500
    );
    --text-on-brand-primary-primary-hover: var(--colour-neutrals-neutral-white);
    --text-on-brand-secondary-primary-default: var(
      --colour-primary-blue-blue-100
    );
    --text-on-brand-secondary-primary-disabled: var(
      --colour-neutrals-neutral-500
    );
    --text-on-brand-secondary-primary-hover: var(--colour-primary-blue-blue-50);
    --text-on-destructive-primary-primary-default: #fff;
    --text-on-destructive-primary-primary-disabled: var(
      --colour-neutrals-neutral-500
    );
    --text-on-destructive-primary-primary-hover: #fff;
    --text-on-destructive-secondary-primary-default: var(
      --colour-primary-red-red-100
    );
    --text-on-destructive-secondary-primary-disabled: var(
      --colour-neutrals-neutral-500
    );
    --text-on-destructive-secondary-primary-hover: var(
      --colour-primary-red-red-50
    );
    --text-on-invert-primary-primary-default: var(
      --colour-neutrals-neutral-white
    );
    --text-on-neutral-primary-primary-default: var(
      --colour-neutrals-neutral-white
    );
    --text-on-neutral-primary-primary-disabled: var(
      --colour-neutrals-neutral-500
    );
    --text-on-neutral-primary-primary-hover: var(--colour-neutrals-neutral-50);
    --text-on-neutral-primary-secondary-default: var(
      --colour-neutrals-neutral-100
    );
    --text-on-neutral-primary-secondary-disabled: var(
      --colour-neutrals-neutral-500
    );
    --text-on-neutral-primary-secondary-hover: var(
      --colour-neutrals-neutral-25
    );
    --text-on-neutral-primary-tertiary-default: var(
      --colour-neutrals-neutral-300
    );
    --text-on-neutral-primary-tertiary-disabled: var(
      --colour-neutrals-neutral-500
    );
    --text-on-neutral-primary-tertiary-hover: var(
      --colour-neutrals-neutral-300
    );
    --text-on-neutral-secondary-primary-default: var(
      --colour-neutrals-neutral-white
    );
    --text-on-neutral-secondary-primary-disabled: var(
      --colour-neutrals-neutral-500
    );
    --text-on-neutral-secondary-primary-hover: var(
      --colour-neutrals-neutral-50
    );
    --text-on-neutral-secondary-secondary-default: var(
      --colour-neutrals-neutral-100
    );
    --text-on-neutral-secondary-secondary-disabled: var(
      --colour-neutrals-neutral-500
    );
    --text-on-neutral-secondary-secondary-hover: var(
      --colour-neutrals-neutral-100
    );
    --text-on-neutral-secondary-tertiary-default: var(
      --colour-neutrals-neutral-300
    );
    --text-on-neutral-secondary-tertiary-disabled: var(
      --colour-neutrals-neutral-500
    );
    --text-on-neutral-secondary-tertiary-hover: var(
      --colour-neutrals-neutral-300
    );
    --text-on-positive-primary-primary-default: var(
      --colour-neutrals-neutral-white
    );
    --text-on-positive-primary-primary-disabled: var(
      --colour-neutrals-neutral-500
    );
    --text-on-positive-primary-primary-hover: var(--colour-neutrals-neutral-50);
    --text-on-positive-secondary-primary-default: var(
      --colour-primary-teal-teal-100
    );
    --text-on-positive-secondary-primary-disabled: var(
      --colour-neutrals-neutral-500
    );
    --text-on-positive-secondary-primary-hover: var(
      --colour-primary-teal-teal-50
    );
    --text-palette-on-green-primary-primary-default: var(
      --colour-neutrals-neutral-white
    );
    --text-palette-on-green-secondary-primary-default: var(
      --colour-secondary-green-green-100
    );
    --text-palette-on-orange-primary-primary-default: var(
      --colour-neutrals-neutral-white
    );
    --text-palette-on-orange-secondary-primary-default: var(
      --colour-secondary-orange-orange-100
    );
    --text-palette-on-pink-primary-primary-default: var(
      --colour-neutrals-neutral-white
    );
    --text-palette-on-pink-secondary-primary-default: var(
      --colour-secondary-pink-pink-100
    );
    --text-palette-on-purple-primary-primary-default: var(
      --colour-neutrals-neutral-white
    );
    --text-palette-on-purple-secondary-primary-default: var(
      --colour-secondary-purple-purple-100
    );
  }
}
