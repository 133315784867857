.container {
  padding: var(--spacing-8px) var(--spacing-10px);
  text-align: left;
}

.explainerRelationship {
  font: var(--text-xs-medium);

  // Prevent these words from breaking on wrap
  white-space: nowrap;
}
