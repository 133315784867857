@use "../utils";

%form {
  display: flex;
  flex-direction: column;
  gap: var(--spacing-16px);
  align-items: flex-end;
  justify-content: center;

  max-width: utils.rem(420);
  padding-top: var(--spacing-16px);
  padding-bottom: var(--spacing-24px);

  &:focus-visible {
    outline: none;
  }
}
