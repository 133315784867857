@keyframes slide-in-up {
  from {
    transform: translateY(10px);
    opacity: 0;
  }

  to {
    transform: translateY(0);
    opacity: 1;
  }
}


$colours: ("brand", "positive", "alert", "destructive");
$hierarchies: ("primary", "secondary");

%toast {
  animation: slide-in-up 150ms;

  @each $colour in $colours {
    @each $hierarchy in $hierarchies {
      &.#{$colour}.#{$hierarchy} {
        @if $colour == "destructive" {
          color: var(--text-on-destructive-#{$hierarchy}-primary-default);
          background-color: var(
            --surface-destructive-#{$hierarchy}-default-fill
          );
          border: var(--stroke-weight-1px)
            solid
            var(--surface-destructive-#{$hierarchy}-default-stroke);
        } @else {
          color: var(--text-on-#{$colour}-#{$hierarchy}-primary-default);

          // Some background vars end in -fill, others don't
          background-color: var(
            --surface-#{$colour}-#{$hierarchy}-default-fill,
            var(--surface-#{$colour}-#{$hierarchy}-default)
          );
          border: var(--stroke-weight-1px)
            solid
            var(--surface-#{$colour}-#{$hierarchy}-default-stroke);
        }
      }
    }
  }
}