%list {
  padding: 4px;
  background-color: var(--surface-neutral-primary-default-fill);
  border-radius: 12px;
}

%list-with-radio-input {
  &:has(input:focus-visible) {
    outline: var(--outline);
  }
}
