@use "../../styles/utils";

.heading, .placeholder {
  /**
   * Breaks vertical rhythm, but larger piece of work
   * around that needed
   */
  margin-top: calc(var(--editor-font-size) * 1.2px);

  font: var(--text-lg-bold);
  font-family: var(--editor-font-family);
  font-size: calc(var(--editor-font-size) * 1.2px);
  line-height: calc(var(--editor-font-size) * 1.61px);
  color: var(--text-on-neutral-primary-primary-default);

  /**
   * Bold should look the same in headers
   */
  strong {
    font-weight: 700; // defaults to 600
  }
}

.placeholder {
  pointer-events: none;
  user-select: none;

  position: absolute;

  display: block;

  max-width: 100%;

  color: var(--text-on-neutral-primary-tertiary-default);
  text-decoration: none;
}
