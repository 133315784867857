%popover {
  overflow: visible;
  border: none;

  &:not([popover]:popover-open) {
    /*
     * Popovers are hidden by default using `display`. For our purposes this is
     * not ideal as we need the element's position on the layout in order to
     * anchor its position prior to it being shown. Use `visibility` instead.
     */
    display: initial;
    visibility: hidden;
  }
}
