.label {
  font: var(--text-sm-semibold);

  &.disabled {
    color: var(--text-on-neutral-primary-primary-disabled);
  }
}

.explainer {
  font: var(--text-xs-medium);
  color: var(--text-on-neutral-primary-secondary-default);
}

.error {
  font: var(--text-sm-semibold);
  color: var(--text-on-destructive-secondary-primary-default);
}

.field {
  display: grid;
  gap: var(--spacing-5px);
  width: 100%;

  &:has(input[type="checkbox"]) {
    grid-template:
      "label input" auto
      "explainer ." auto
      "error ." auto / 1fr auto;
    gap: var(--spacing-2px) var(--spacing-12px);
    align-items: center;

    & .label {
      grid-area: label;
    }

    & .input {
      grid-area: input;
    }

    & .explainer {
      grid-area: explainer;
    }

    & .error {
      grid-area: error;
    }
  }
}
