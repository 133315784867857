@use "../../../styles/utils.scss";

@keyframes slideDown {
  to {
    transform: translateY(0);
  }
}

.container {
  user-select: none;

  display: flex;
  align-items: center;
  justify-content: space-between;

  padding: 16px;

  font-family: system-ui;

  background-color: var(--pwa-install-prompt-container-background-colour);
  border-bottom: 1px solid var(--pwa-install-prompt-border-colour);

  &.slideIn {
    transform: translateY(-100%);
    animation: slideDown 0.35s ease-out 0.75s forwards;

    @media (prefers-reduced-motion: reduce) {
      transform: none;
    }
  }
}

.explanation {
  display: flex;
  gap: 8px;
  align-items: center;
}

.appIcon {
  display: flex;
  width: 28px;
  height: 28px;

  & svg {
    width: 100%;
    height: 100%;
  }
}

.explanationText {
  display: flex;
  flex-direction: column;
}

.appName {
  font-size: utils.rem(12);
  font-weight: 400;
  line-height: utils.rem(18);
  color: var(--pwa-install-prompt-app-name-text-colour);
}

.installToHomeScreen {
  font-size: utils.rem(11);
  font-weight: 400;
  line-height: utils.rem(13);
  color: var(--native-install-to-home-screen-text-colour);
}

.actions {
  display: flex;
  gap: 16px;
  font-size: utils.rem(14);
}

.cancelButton {
  font-size: utils.rem(14);
  font-weight: 400;
  line-height: utils.rem(17);
  color: var(--native-instruction-cancel-button-colour);
  letter-spacing: 0.03em;

  border: none;
  border-radius: 999px;

  &:hover {
    text-decoration: underline;
  }
}

.installButton {
  padding: 7px 13px;

  font-size: utils.rem(14);
  font-weight: 500;
  line-height: utils.rem(17);
  color: var(--native-install-button-text-colour);
  letter-spacing: 0.03em;

  background-color: var(--native-install-button-background-colour);
  border: none;
  border-radius: 999px;

  transition: background-color 0.2s ease-in-out;

  &:hover {
    background-color: var(--native-install-button-hover-background-colour);
  }
}
