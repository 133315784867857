.button {
  user-select: none;

  display: flex;
  gap: var(--spacing-4px);
  align-items: center;

  padding: var(--spacing-4px) var(--spacing-10px);

  font: var(--text-xs-bold);
  color: var(--text-on-neutral-primary-secondary-default);

  background: var(--surface-neutral-secondary-default-fill);
  border: 1px solid var(--surface-neutral-secondary-default-stroke);
  border-radius: var(--radius-8px);
  box-shadow: var(--elevation-1);

  &:disabled {
    cursor: default;
  }

  &:not(:disabled) {
    &:hover,
    &[aria-expanded="true"] {
      background: var(--surface-neutral-secondary-hover);
      border-color: var(--surface-neutral-secondary-default-stroke);
    }
  }
}

.icon {
  display: flex;
  color: var(--icon-on-neutral-primary-secondary-default);
}
