.container {
  display: flex;
  align-items: center;
  justify-content: space-between;

  min-height: 52px;
  padding: 0 16px;

  background-color: var(--surface-neutral-primary-default-fill);

  &.rounded {
    border-radius: 16px 16px 0 0;
  }
}

.controls {
  display: grid;
  align-content: center;
  height: 100%;
}

.userMenuButton {
  display: flex;
  grid-area: user-menu-button;
  align-items: center;
}

.openLibraryButton {
  grid-area: open-library-button;
}

.addDocumentButton {
  grid-area: add-document-button;
}

.libraryDisabled {
  grid-template-columns: [user-menu-button] min-content;

  & .openLibraryButton,
  & .addDocumentButton {
    display: none;
  }
}

.libraryOpen {
  grid-template-columns: [add-document-button] min-content;

  & .userMenuButton {
    display: none;

    // Transition a bit earlier than drawer to reduce "bounce" at the end
    transition: display 0ms ease-out
      calc(0.9 * var(--drawer-transition-duration)) allow-discrete;
  }

  & .openLibraryButton {
    display: none;
  }
}

.libraryClosed {
  grid-template-columns:
    [user-menu-button] 32px
    [big-gap] 8px
    [open-library-button] min-content
    [small-gap] 2px
    [add-document-button] min-content;
}
